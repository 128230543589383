// --------------------------------------------------------------------------
import { NbrFilterProps } from './NeedsBasedHeroSection';
import { NeedsBasedRecOption } from './selection.atom';

export const NEEDS_BASED_RECOMMENDATIONS_DEFAULT_DATA: NbrFilterProps = {
  pillData: {
    [NeedsBasedRecOption.BUDGETING_AND_SAVING]: {
      title: 'Budgeting & saving',
      icon: 'PiggyBank',
    },
    [NeedsBasedRecOption.INVESTING]: {
      title: 'Investing',
      icon: 'ChartUp',
    },
    [NeedsBasedRecOption.PERSONALIZED_FINANCIAL_PLANNING]: {
      title: 'Financial planning',
      icon: 'PieChart',
    },
    [NeedsBasedRecOption.RETIREMENT_PLANNING]: {
      title: 'Retirement planning',
      icon: 'MoneyTime',
    },
    [NeedsBasedRecOption.GETTING_INSURED]: {
      title: 'Getting insured',
      icon: 'Grow',
    },
    [NeedsBasedRecOption.GENEROSITY_AND_GIVING]: {
      title: 'Generosity & giving',
      icon: 'GroupHeart',
    },
  },
  titleSectionData: {
    title: 'Better money decisions start with a conversation',
    inquiry: 'What can we help you with?',
  },
  leftContentData: {
    [NeedsBasedRecOption.PERSONALIZED_FINANCIAL_PLANNING]: {
      title: 'Financial planning',
      description:
        "Financial planning is personal. That's why our conversations focus on your unique values and goals.",
      list: [
        'Your values, financial needs, and goals',
        'How to build a financial plan with a strong foundation',
      ],
    },
    [NeedsBasedRecOption.BUDGETING_AND_SAVING]: {
      title: 'Budgeting & saving',
      description:
        'Budgets are a powerful tool that help you align your everyday money decisions to your values.',
      list: [
        'Managing your cash flow',
        'Paying down debt',
        'Saving, investing, and giving',
      ],
    },
    [NeedsBasedRecOption.RETIREMENT_PLANNING]: {
      title: 'Retirement planning',
      description:
        'Whether you are nearing retirement or just starting out, we offer advice and tax-efficient saving strategies to help you achieve your goals.',
      list: [
        'How you want to spend your retirement',
        'Your investment risk tolerance',
        'Types of investment accounts',
      ],
    },
    [NeedsBasedRecOption.INVESTING]: {
      title: 'Investing',
      description:
        'We start by understanding the true purpose for the money you want to invest. Then we build a strategy to help achieve your goals.',
      list: [
        'Your investing goals and experience',
        'Factors that impact your risk tolerance',
      ],
    },
    [NeedsBasedRecOption.GENEROSITY_AND_GIVING]: {
      title: 'Generosity & giving',
      description:
        'Giving can be an important and fulfilling aspect of your financial plan. We can provide you charitable giving expertise.',
      list: [
        'Different causes that you care about',
        'Tax-efficient options for charitable giving',
      ],
    },
    [NeedsBasedRecOption.GETTING_INSURED]: {
      title: 'Getting insured',
      description:
        'No one can predict the future, but we can help you prepare for unexpected surprises.',
      list: [
        'Protecting your income from unexpected losses',
        "Protecting your family's financial future",
      ],
    },
  },
  rightContentData: {
    [NeedsBasedRecOption.PERSONALIZED_FINANCIAL_PLANNING]: {
      links: [
        {
          linkText: 'The Psychology of Financial Planning',
          href: 'https://www.thrivent.com/insights/financial-planning/the-psychology-of-financial-planning',
          icon: 'Document',
        },
        {
          linkText: 'Building Your Financial House',
          videoId: 'k2uE3qXO6jKjH5PEKAQvNK',
        },
      ],
    },
    [NeedsBasedRecOption.BUDGETING_AND_SAVING]: {
      links: [
        {
          linkText: 'Pay Yourself First: Save More with the 80/20 Budget',
          href: 'https://www.thrivent.com/insights/budgeting-saving/what-does-it-mean-to-pay-yourself-first',
          icon: 'Document',
        },
        {
          linkText: 'Try our free money coaching service',
          href: '/generosity/money-canvas',
          icon: 'Assessment',
        },
      ],
    },
    [NeedsBasedRecOption.RETIREMENT_PLANNING]: {
      links: [
        {
          linkText: 'The Ultimate Guide to Retirement by Age',
          href: 'https://www.thrivent.com/insights/retirement-planning/the-ultimate-guide-to-retirement-savings-by-age',
          icon: 'Document',
        },
        {
          linkText: 'Try our retirement income match tool',
          href: 'https://income-match-assessment.apps.thrivent.com/',
          icon: 'Document',
        },
      ],
    },
    [NeedsBasedRecOption.INVESTING]: {
      links: [
        {
          linkText: 'Understanding Your Risk Tolerance',
          href: 'https://www.thrivent.com/insights/investing/why-understanding-your-risk-tolerance-can-lead-to-smarter-investments',
          icon: 'Document',
        },
      ],
    },
    [NeedsBasedRecOption.GENEROSITY_AND_GIVING]: {
      links: [
        {
          linkText: 'Family Giving Strategies',
          href: 'https://www.thrivent.com/insights/generosity-giving/family-giving-strategies-getting-everyone-involved-in-living-generously',
          icon: 'Document',
        },
        {
          linkText: 'Tax-efficient Tools for Charitable Giving',
          href: 'https://www.thrivent.com/insights/generosity-giving/tools-for-tax-efficient-charitable-giving',
          icon: 'Document',
        },
      ],
    },
    [NeedsBasedRecOption.GETTING_INSURED]: {
      links: [
        {
          linkText: 'Do You Need Life Insurance? Questions to Ask',
          href: 'https://www.thrivent.com/insights/life-insurance/do-i-need-life-insurance-questions-to-ask-yourself-and-your-family',
          icon: 'Document',
        },
      ],
    },
  },
};
