import { ArrowRightIcon } from '../../svg';
import { ChevronRight as ChevronRightIcon } from '@exploration/shared-components';
import { MDSAnchorButton, MDSText } from '@midwest/web/base';
import { Link } from '@midwest/web/link';
import { desktop, textBodyRegDefault } from '@thrivent/midwest-shared';
import styled from 'styled-components';

// --------------------------------------------------------------------------

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Description = styled(MDSText.BodyRegDefault)`
  margin-top: 1rem;
`;

export const Subtitle = styled(MDSText.BodyHeavySm)`
  margin-block: 0.75rem 0.5rem;
  margin-bottom: 1rem;
`;

export const LinkContainer = styled.div`
  display: flex;
`;

export const ProductLink = styled(Link)`
  font-weight: ${textBodyRegDefault.weight};
  margin-bottom: 1rem;
  margin-left: 0.5rem;
  margin-right: 0.1rem;
`;

export const Button = styled(MDSAnchorButton)`
  margin-top: 0.75rem;
  background-color: ${(p) =>
    p.theme.midwestColors.componentButtonColorTextGhostPrimary};
  width: auto;

  ${desktop} {
    width: fit-content;
  }
`;
export const List = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
`;

export const ChevronRight = styled(ChevronRightIcon)`
  color: ${(p) => p.theme.midwestColors.componentLinkHover};
`;

export const Arrow = styled(ArrowRightIcon)`
  margin-top: 0.3rem;
`;
export const ListItem = styled.li`
  gap: 0.5rem;
  padding-bottom: 1rem;
  display: flex;
`;
