'use client';

import { SectionContainer } from '@exploration/shared-components';
import { desktop, shadow, tablet } from '@thrivent/midwest-shared';
import styled from 'styled-components';

// --------------------------------------------------------------------------

export const Container = styled(SectionContainer)`
  min-height: fit-content;
  align-items: center;
  padding-top: 3rem;
  padding-bottom: 3rem;

  ${desktop} {
    flex-direction: column;
  }
`;

export const CardContainer = styled.div`
  grid-template-columns: 1fr;
  display: grid;
  gap: 3.5rem;
  padding-top: 4rem;

  ${tablet}, ${desktop} {
    grid-template-columns: repeat(2, 1fr);
    gap: 1.5rem;
  }

  ${desktop} {
    padding-top: 6rem;
  }
`;

export const BoxContainer = styled.div`
  padding: 1.5rem;
  border-radius: 0.375rem;
  box-shadow: ${shadow.sm};
  flex: 1;
  align-items: stretch;
  display: flex;
  flex-direction: column;
  max-width: 28rem;
`;
