'use client';

import {
  ChartUp,
  GroupHeartIcon,
  Grow,
  MoneyTime,
  PieChart,
  PiggyBank,
} from '../../svg';
import { LeftContent, LeftContentProps } from './LeftContent';
import {
  ContentSectionStyles,
  PillsSectionStyles,
  TopSectionStyles,
} from './NeedsBasedHeroSection.style';
import { RightContent, RightContentProps } from './RightContent';
import { NEEDS_BASED_RECOMMENDATIONS_DEFAULT_DATA } from './default-data';
import { NeedsBasedRecOption, needsBasedRecAtom } from './selection.atom';
import { ChevronLeftIcon, ChevronRightIcon } from '@radix-ui/react-icons';
import { useAtom } from 'jotai';
import { useCallback } from 'react';

const { NBRContainerSection, TopSection, TopContainer, TopTitle, TopInquiry } =
  TopSectionStyles;
const {
  PillsSection,
  PillsBanner,
  PillsContainer,
  Pill,
  PillsButton,
  PillsButtonContainer,
} = PillsSectionStyles;
const { ContentSection, ContentContainer, ContentSectionCard } =
  ContentSectionStyles;

type Pill = {
  title: string;
  icon: string;
};

export interface NbrFilterProps extends LeftContentProps, RightContentProps {
  pillData?: {
    [NeedsBasedRecOption.PERSONALIZED_FINANCIAL_PLANNING]: Pill;
    [NeedsBasedRecOption.BUDGETING_AND_SAVING]: Pill;
    [NeedsBasedRecOption.RETIREMENT_PLANNING]: Pill;
    [NeedsBasedRecOption.INVESTING]: Pill;
    [NeedsBasedRecOption.GENEROSITY_AND_GIVING]: Pill;
    [NeedsBasedRecOption.GETTING_INSURED]: Pill;
  };
  titleSectionData?: {
    title: string;
    inquiry: string;
  };
}

const Icons = {
  PieChart: PieChart,
  PiggyBank: PiggyBank,
  MoneyTime: MoneyTime,
  ChartUp: ChartUp,
  Grow: Grow,
  GroupHeart: GroupHeartIcon,
};

export const NeedsBasedHeroSection = ({
  pillData,
  titleSectionData,
  leftContentData,
  rightContentData,
}: NbrFilterProps) => {
  const [needsBasedData, setNeedsBasedData] = useAtom(needsBasedRecAtom);

  const isSelected = useCallback(
    (option: NeedsBasedRecOption) => needsBasedData.selectedOption === option,
    [needsBasedData.selectedOption],
  );
  const selectedKey = Object.entries(NeedsBasedRecOption).find(
    ([, value]) => value === needsBasedData.selectedOption,
  )?.[0];
  const tabId = `tab-${selectedKey}`;
  const panelId = `tabpanel-${selectedKey}`;

  const increment = () => {
    const containerElement = document.getElementById('tablist');
    containerElement?.scrollBy({
      left: 200,
      behavior: 'smooth',
    });
  };

  const decrement = () => {
    const containerElement = document.getElementById('tablist');
    containerElement?.scrollBy({
      left: -200,
      behavior: 'smooth',
    });
  };

  return (
    <NBRContainerSection className="nbr-embedded-section">
      <TopSection data-testid="TopSection">
        <TopContainer>
          <TopTitle as="h1">{titleSectionData?.title}</TopTitle>
          <TopInquiry>{titleSectionData?.inquiry}</TopInquiry>
        </TopContainer>
      </TopSection>
      <PillsSection data-testid="PillsSection">
        <PillsBanner />
        <PillsButtonContainer>
          <PillsButton left onClick={decrement}>
            <ChevronLeftIcon />
          </PillsButton>
          <PillsContainer role="tablist" id="tablist">
            {Object.entries(NeedsBasedRecOption).map(([key, value]) => {
              const currentPill = pillData?.[key as NeedsBasedRecOption];
              const IconComponent =
                Icons[currentPill?.icon as keyof typeof Icons];
              const isSelectedTab = isSelected(value as NeedsBasedRecOption);

              return (
                <Pill
                  key={key}
                  onClick={() => {
                    setNeedsBasedData({
                      selectedOption: value as NeedsBasedRecOption,
                    });
                  }}
                  selected={isSelectedTab}
                  label={
                    <>
                      <IconComponent />
                      <div>{currentPill?.title}</div>
                    </>
                  }
                  component="button"
                  role="tab"
                  id={tabId}
                  aria-label={currentPill?.title}
                  aria-selected={isSelectedTab}
                  aria-controls={panelId}
                  tabIndex={isSelectedTab ? 0 : -1}
                />
              );
            })}
          </PillsContainer>
          <PillsButton onClick={increment}>
            <ChevronRightIcon />
          </PillsButton>
        </PillsButtonContainer>
      </PillsSection>
      <ContentSection
        data-testid="ChangeableSection"
        id={panelId}
        role="tabpanel"
        aria-labelledby={tabId}
      >
        <ContentContainer>
          <ContentSectionCard>
            <LeftContent {...{ leftContentData }} />
          </ContentSectionCard>
        </ContentContainer>
        <ContentContainer>
          <ContentSectionCard>
            <RightContent {...{ rightContentData }} />
          </ContentSectionCard>
        </ContentContainer>
      </ContentSection>
    </NBRContainerSection>
  );
};

export const NeedsBasedHeroSectionWithDefaults = () => (
  <NeedsBasedHeroSection {...NEEDS_BASED_RECOMMENDATIONS_DEFAULT_DATA} />
);
