'use client';

import LearnMoreCard, { LearnMoreCardProps } from './learn-more-card';
import { LearnMoreStyles } from './learn-mores.style';

// --------------------------------------------------------------------------

export interface LearnMoreSectionProps {
  title: string;
  subtitle: string;
  cards: LearnMoreCardProps[];
}

// --------------------------------------------------------------------------

export const LearnMoreSection = ({
  title,
  subtitle,
  cards,
}: LearnMoreSectionProps) => {
  return (
    <LearnMoreStyles.RootContainer>
      <LearnMoreStyles.TextContainer>
        <LearnMoreStyles.Title as="h2">{title}</LearnMoreStyles.Title>
        <LearnMoreStyles.Description as={'p'}>
          {subtitle}
        </LearnMoreStyles.Description>
      </LearnMoreStyles.TextContainer>
      <LearnMoreStyles.SectionContainer>
        <LearnMoreStyles.GridCardsRow>
          {cards?.map((card) => (
            <LearnMoreCard
              key={card.title}
              category={card.category}
              title={card.title}
              description={card.description}
              linkButtonTitle={card.linkButtonTitle}
              linkButtonHref={card.linkButtonHref}
              linkButtonId={card.linkButtonId}
              linkButtonAriaLabel={card.linkButtonAriaLabel}
            />
          ))}
        </LearnMoreStyles.GridCardsRow>
      </LearnMoreStyles.SectionContainer>
    </LearnMoreStyles.RootContainer>
  );
};
