const IconPieChart = ({
  iconColor = '#2E66FF',
  ...props
}: {
  iconColor?: string;
}) => (
  <svg
    width="46"
    height="42"
    viewBox="0 0 46 42"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M38.9397 22.4103C38.9397 32.8456 30.4799 41.3054 20.0446 41.3054C9.60778 41.3054 1.14795 32.8456 1.14795 22.4103C1.14795 11.975 9.60778 3.51518 20.0446 3.51518"
      stroke={iconColor}
      strokeWidth="1.33333"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.0435 3.51443C22.4434 3.51443 24.7384 3.96142 26.8488 4.7774"
      stroke={iconColor}
      strokeWidth="1.33333"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1 22.4103H19.8951L33.3019 36.0435"
      stroke={iconColor}
      strokeWidth="1.33333"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.0435 22.1151V4.02548"
      stroke={iconColor}
      strokeWidth="1.33333"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M44.5371 17.4436C44.4831 9.74426 40.1407 4.11337 33.8258 1.30543L25.4814 19.0726L44.5371 17.4436Z"
      stroke={iconColor}
      strokeWidth="1.33333"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

// --------------------------------------------------------------------------

export default IconPieChart;
