'use client';

import { AdvisorButton } from '../../brand-refresh/AdvisorButton';
import {
  Container,
  HStack,
  InputLabel,
  SearchInput,
  StyledContainer,
  VStack,
} from './SearchAdvisorInput.styles';
import { useState } from 'react';

type Props = {
  ctaButton: string;
  ctaLabel: string;
  ctaText: string;
  buttonOnly?: boolean;
  id?: string;
  showChevron?: boolean;
};

export const SearchAdvisorInput = ({
  ctaLabel,
  ctaButton,
  ctaText,
  buttonOnly = false,
  id,
  showChevron = true,
}: Props) => {
  const [inputValue, setInputValue] = useState('');

  return buttonOnly ? (
    <AdvisorButton
      id={id}
      showChevron={showChevron}
      ctaButton={ctaButton}
      advisorSearchText={inputValue}
    />
  ) : (
    <Container>
      <StyledContainer>
        <VStack>
          <InputLabel>{ctaLabel}</InputLabel>
          <HStack>
            <SearchInput
              name="search-input"
              label="Search by location"
              placeholder={ctaText}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setInputValue(e.currentTarget.value)
              }
            />
            <AdvisorButton
              ctaButton={ctaButton}
              id={id}
              showChevron={showChevron}
              advisorSearchText={inputValue}
            />
          </HStack>
        </VStack>
      </StyledContainer>
    </Container>
  );
};
