const IconAwardDoc = ({
  iconColor = '#2E66FF',
  ...props
}: {
  iconColor?: string;
}) => (
  <svg
    width="30"
    height="42"
    viewBox="0 0 30 42"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.7833 37.522H1V0.99999H29.2207V37.522H26.6675"
      stroke={iconColor}
      strokeWidth="1.33333"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M24.882 27.6382C24.882 30.6747 22.4201 33.1366 19.3836 33.1366C16.3472 33.1366 13.8853 30.6747 13.8853 27.6382C13.8853 24.6018 16.3472 22.1399 19.3836 22.1399C22.4201 22.1399 24.882 24.6018 24.882 27.6382Z"
      stroke={iconColor}
      strokeWidth="1.33333"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M23.8687 30.9899V41L19.3838 37.2682L14.8989 41V30.9899"
      stroke={iconColor}
      strokeWidth="1.33333"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.81787 6.43945H25.4045"
      stroke={iconColor}
      strokeWidth="1.33333"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.81787 9.50214H25.4045"
      stroke={iconColor}
      strokeWidth="1.33333"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.81787 12.5649H25.4045"
      stroke={iconColor}
      strokeWidth="1.33333"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.81787 15.6276H20.8418"
      stroke={iconColor}
      strokeWidth="1.33333"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

// --------------------------------------------------------------------------

export default IconAwardDoc;
