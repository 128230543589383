'use client';

import {
  BreakoutSectionContainer,
  SectionRoot,
  breakoutSectionWidthBreakpoint,
  sectionContainerBreakpoint,
  sectionContainerMockPadding,
} from '@exploration/shared-components';
import { desktop } from '@thrivent/midwest-shared';
import { MDSText } from '@thrivent/midwest-web';
import nextImage from 'next/image';
import styled from 'styled-components';

// --------------------------------------------------------------------------

export const Section = styled(SectionRoot)`
  background-color: ${(p) => p.theme.midwestColors.identityBrandTertiaryFaint};
`;

export const Container = styled(BreakoutSectionContainer)`
  padding: 0;

  ${desktop} {
    min-height: 30rem;
  }
`;

export const ImageContainer = styled.div`
  padding: 0;
`;

export const Image = styled(nextImage)`
  border-radius: 0.25rem;
  width: 8.25rem;
  height: auto;

  ${desktop} {
    width: 100%;
  }
`;

export const ImagesRow = styled.div`
  display: flex;
  gap: 0.25rem;
  flex-shrink: 0;

  &:not(:first-of-type) ${ImageContainer}:first-of-type {
    display: none;
  }

  ${desktop} {
    margin-top: -1rem;
    flex-direction: column;
    flex-shrink: 1;
    width: 100%;
    gap: 0.5rem;

    &:not(:first-of-type) ${ImageContainer}:first-of-type {
      display: block;
    }
  }

  &:nth-of-type(2n + 0) {
    ${desktop} {
      margin-top: -7rem;
    }
  }
`;

// Left section desktop - Image
export const ImagesContainer = styled.div`
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 12rem;

  ${ImagesRow}:last-of-type {
    display: none;
  }

  ${desktop} {
    width: 50%;
    height: auto;
  }

  @media screen and (min-width: 1600px) {
    ${ImagesRow}:last-of-type {
      display: flex;
    }
  }
`;

export const ImagesContainerInner = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  overflow: hidden;
  display: flex;
  gap: 0.25rem;
  overflow-y: hidden;

  ${desktop} {
    gap: 0.5rem;
    overflow: hidden;
  }
`;

// Right section - Content and input
export const ContentContainer = styled.div`
  justify-content: center;
  display: flex;
  padding-inline: 1rem;

  ${desktop} {
    justify-content: flex-end;
    width: 50%;
    padding-block: 0rem;
  }

  ${sectionContainerBreakpoint} {
    padding-right: ${sectionContainerMockPadding};
  }

  ${breakoutSectionWidthBreakpoint} {
    padding-right: 24.5rem;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding-block: 1.5rem 3rem;
  ${desktop} {
    max-width: 30.25rem;
    padding-block: 6.75rem;
    padding-right: 0.75rem;
  }
`;

export const Title = styled(MDSText.HeroMd)``;

export const Description = styled(MDSText.BodyRegDefault)``;
