import { containerPaddingRem, containerWidthRem } from './container';
import { SectionContainer } from './section-container';
import { desktop } from '@thrivent/midwest-shared';
import styled from 'styled-components';

// --------------------------------------------------------------------------

export const breakoutSectionWidthPixels = 2000;
export const breakoutSectionWidthRem = 125;
export const breakoutSectionWidthBreakpoint = `@media screen and (min-width: ${breakoutSectionWidthPixels}px)`;

// Because we're actually using a larger breakpoint, we have to "fake"
// the SectionContainer for the content
export const sectionContainerMockPaddingRem =
  containerWidthRem - containerPaddingRem;

export const sectionContainerMockPadding = `calc((100vw - ${sectionContainerMockPaddingRem}rem) / 2)`;

// --------------------------------------------------------------------------

/** @deprecated Use `@exploration/shared-components/GridContainer`. */
export const BreakoutSectionContainer = styled(SectionContainer)`
  ${desktop} {
    max-width: ${breakoutSectionWidthRem}rem;
  }
`;
