'use client';

import { Chart, Document, PlayIcon } from '../../svg';
import {
  Arrow,
  ButtonLinkVideo,
  IndentedModalListItem,
  LifeMilestonesListTitle,
  LifeMilestonesModalImageContainer,
  LifeMilestonesModalList,
  LifeMilestonesModalTextMain,
  LifeMilestonesModalTextSidebar,
  LifeMilestonesSidebarText,
  LinkArrowContainer,
  LinkContainer,
  ModalDescription,
  ModalImage,
  ModalListItem,
  ModalListItemChevron,
  ModalSidebarList,
  ModalTextContainer,
  ModalTitle,
  ProductLink,
  RelatedResourcesMilestones,
  VideoLink,
} from './ModalCardSection.styles';
import { ModalCardSectionCard } from './ModalCardSection.types';
import { sanitize } from 'isomorphic-dompurify';
import { Fragment } from 'react';
import ReactHtmlParser from 'react-html-parser';

interface ListObject {
  mainBullet: string;
  subBullets?: string[];
}

const buildListObjectComponent = (listObjects: ListObject[]) => {
  const createNumberedList = (num: number) => {
    return num + 1 + '. ';
  };

  return (
    <LifeMilestonesModalList>
      {listObjects.map((listObject: ListObject, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Fragment key={index}>
          <ModalListItem as="li">
            <p>{createNumberedList(index)}</p>
            <p>{ReactHtmlParser(sanitize(listObject.mainBullet || ''))}</p>
          </ModalListItem>
          {listObject.subBullets && (
            <LifeMilestonesModalList as="ul">
              {listObject.subBullets.map((subBullet, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <IndentedModalListItem key={index} as="li">
                  <ModalListItemChevron />
                  <p>{ReactHtmlParser(sanitize(subBullet || ''))}</p>
                </IndentedModalListItem>
              ))}
            </LifeMilestonesModalList>
          )}
        </Fragment>
      ))}
    </LifeMilestonesModalList>
  );
};

const buildListItemsComponent = (listItems: string[]) => {
  return (
    <LifeMilestonesModalList as="ul">
      {listItems.map((listItem: string, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <ModalListItem key={index} as="li">
          <ModalListItemChevron />
          {ReactHtmlParser(sanitize(listItem || ''))}
        </ModalListItem>
      ))}
    </LifeMilestonesModalList>
  );
};

export const LifeMilestonesSectionModal = ({
  card,
  resetModal,
}: {
  className?: string;
  card: ModalCardSectionCard;
  resetModal: () => void;
}) => {
  return (
    <>
      <LifeMilestonesModalImageContainer>
        <ModalImage
          {...card.modalImage}
          height={undefined}
          width={undefined}
          fill
        ></ModalImage>
      </LifeMilestonesModalImageContainer>
      <ModalTextContainer>
        <LifeMilestonesModalTextMain>
          <ModalTitle as="h3">{card.title}</ModalTitle>
          <ModalDescription as="p">
            {ReactHtmlParser(sanitize(card.description || ''))}
          </ModalDescription>
          {!!card?.sidebarTitle && !!card?.sidebarLinks?.length && (
            <RelatedResourcesMilestones as="h4">
              {card.sidebarTitle}
            </RelatedResourcesMilestones>
          )}
          {!!card?.sidebarLinks?.length && (
            <ModalSidebarList>
              {card.sidebarLinks.map((sidebarLink, i) => {
                return (
                  // eslint-disable-next-line react/no-array-index-key
                  <LinkContainer key={`${card.id}-modal-link-${i}`}>
                    {sidebarLink.type === 'video' ? (
                      <ButtonLinkVideo
                        titleText={sidebarLink.text}
                        videoId={sidebarLink?.videoId ?? ''}
                        linkComponent={
                          <VideoLink
                            data-analytics-id={`${card.id}-modal-link-${i}`}
                          >
                            <PlayIcon />
                            <LinkArrowContainer>
                              {sidebarLink.text}
                              <Arrow />
                            </LinkArrowContainer>
                          </VideoLink>
                        }
                      />
                    ) : (
                      <ProductLink
                        href={sidebarLink.href}
                        type="navigational"
                        data-analytics-id={`${card.id}-modal-link-${i}`}
                        onClick={() => resetModal()}
                      >
                        {sidebarLink.icon === 'document' && <Document />}
                        {sidebarLink.icon === 'chart' && <Chart />}
                        <LinkArrowContainer onClick={() => resetModal()}>
                          {sidebarLink.text}
                          <Arrow />
                        </LinkArrowContainer>
                      </ProductLink>
                    )}
                  </LinkContainer>
                );
              })}
            </ModalSidebarList>
          )}
        </LifeMilestonesModalTextMain>
        <LifeMilestonesModalTextSidebar>
          {(!!card?.listItems?.length || !!card?.multiListItems?.length) && (
            <>
              <LifeMilestonesListTitle as="h4">
                {card.listTitle}
              </LifeMilestonesListTitle>
              <LifeMilestonesSidebarText as="p">
                {ReactHtmlParser(sanitize(card.listDescription || ''))}
              </LifeMilestonesSidebarText>
              {card.multiListItems &&
                buildListObjectComponent(card.multiListItems)}
              {card.listItems && buildListItemsComponent(card.listItems)}
              <LifeMilestonesSidebarText as="p">
                {ReactHtmlParser(sanitize(card.listClosing || ''))}
              </LifeMilestonesSidebarText>
            </>
          )}
        </LifeMilestonesModalTextSidebar>
      </ModalTextContainer>
    </>
  );
};
