'use client';

import {
  Container,
  FlexContainer,
  Image,
  ImageContainer,
  MobileSliderButtonL,
  MobileSliderButtonR,
  STitle,
  Section,
  SlideDescription,
  SlideLink,
  SlideLinkChevronRight,
  SlideTitle,
  SliderChevronButton,
  SliderContainer,
  SliderContent,
  SliderContentContainer,
  SliderControls,
  SliderControlsChevronLeft,
  SliderControlsChevronRight,
} from './fade-slider-v2.style';
import { desktopBreakpoint } from '@thrivent/midwest-shared';
import { ImageProps } from 'next/image';
import { useEffect, useState } from 'react';

// --------------------------------------------------------------------------

export interface FadeSliderSectionPropsV2 {
  title?: string;
  className?: string;
  slides: {
    image?: ImageProps;
    titleText?: string;
    descriptionText?: string;
    link?: {
      text: string;
      href: string;
      id: string;
      'aria-label': string;
    };
  }[];
}

// --------------------------------------------------------------------------

const setToSlide = (
  index: number,
  canClick: boolean,
  setActiveSlide: (arg0: number) => void,
  setCanClick: (arg0: boolean) => void,
) => {
  if (!canClick) {
    return;
  }

  setCanClick(false);
  setTimeout(() => setCanClick(true), 300);
  setActiveSlide(index);
};

const nextSlide = (
  activeSlide: number,
  slides: FadeSliderSectionPropsV2['slides'],
  canClick: boolean,
  setActiveSlide: (arg0: number) => void,
  setCanClick: (arg0: boolean) => void,
) => {
  const index = activeSlide + 1 < slides.length ? activeSlide + 1 : 0;
  setToSlide(index, canClick, setActiveSlide, setCanClick);
};

const prevSlide = (
  activeSlide: number,
  slides: FadeSliderSectionPropsV2['slides'],
  canClick: boolean,
  setActiveSlide: (arg0: number) => void,
  setCanClick: (arg0: boolean) => void,
) => {
  const index = activeSlide - 1 >= 0 ? activeSlide - 1 : slides.length - 1;
  setToSlide(index, canClick, setActiveSlide, setCanClick);
};

// --------------------------------------------------------------------------

export const FadeSliderSectionV2 = ({
  title,
  className,
  slides,
}: FadeSliderSectionPropsV2) => {
  const [activeSlide, setActiveSlide] = useState<number>(0);
  const [canClick, setCanClick] = useState<boolean>(true);
  const [userInteraction, setUserInteraction] = useState<boolean>(false);

  useEffect(() => {
    const intervalCallback = () => {
      if (!userInteraction) {
        nextSlide(activeSlide, slides, canClick, setActiveSlide, setCanClick);
      }
    };

    const interval = setInterval(intervalCallback, 3000);

    return () => {
      clearInterval(interval);
    };
  }, [userInteraction, activeSlide, canClick, slides]);

  // Just bail without slides
  if (!Array.isArray(slides) || !slides.length) {
    return null;
  }

  return (
    <Section className={className}>
      <Container>
        <STitle as="h2">{title}</STitle>
        <FlexContainer>
          <SliderContainer>
            <SliderContentContainer>
              {slides.map((slide, index) => {
                return (
                  <SliderContent
                    data-testid={`fade-slide-${index}`}
                    key={slide.titleText}
                    className={index === activeSlide ? 'active' : ''}
                  >
                    {!!slide.titleText && (
                      <SlideTitle as="h3">{slide.titleText}</SlideTitle>
                    )}
                    {!!slide.descriptionText && (
                      <SlideDescription as={'p'}>
                        {slide.descriptionText}
                      </SlideDescription>
                    )}
                    {slide.link && (
                      <SlideLink
                        id={slide.link.id}
                        href={slide.link.href}
                        aria-label={slide.link['aria-label']}
                        onClick={() => {
                          setUserInteraction(true);
                        }}
                      >
                        {slide.link.text} <SlideLinkChevronRight />
                      </SlideLink>
                    )}
                  </SliderContent>
                );
              })}
            </SliderContentContainer>
            <SliderControls as="div">
              <SliderChevronButton
                data-testid={`fade-slider-button-prev-desktop`}
                onClick={() => {
                  setUserInteraction(true);
                  prevSlide(
                    activeSlide,
                    slides,
                    canClick,
                    setActiveSlide,
                    setCanClick,
                  );
                }}
                title="Previous Slide"
                aria-label="Previous Slide"
              >
                <SliderControlsChevronLeft />
              </SliderChevronButton>
              <SliderChevronButton
                data-testid={`fade-slider-button-next-desktop`}
                onClick={() => {
                  setUserInteraction(true);
                  nextSlide(
                    activeSlide,
                    slides,
                    canClick,
                    setActiveSlide,
                    setCanClick,
                  );
                }}
                title="Next Slide"
                aria-label="Next Slide"
              >
                <SliderControlsChevronRight />
              </SliderChevronButton>
            </SliderControls>
          </SliderContainer>
          <ImageContainer>
            <MobileSliderButtonL
              data-testid={`fade-slider-button-prev-mobile`}
              onClick={() => {
                setUserInteraction(true);
                prevSlide(
                  activeSlide,
                  slides,
                  canClick,
                  setActiveSlide,
                  setCanClick,
                );
              }}
              title="Previous Slide"
              aria-label="Previous Slide"
            >
              <SliderControlsChevronLeft />
            </MobileSliderButtonL>
            {slides.map((slide, index) => {
              if (!slide?.image) {
                return null;
              }
              return (
                <Image
                  data-testid={`fade-slide-image-${index}`}
                  key={slide.titleText}
                  className={index === activeSlide ? 'active' : ''}
                  fill
                  priority
                  quality={100}
                  sizes={`(max-width: ${desktopBreakpoint}px) 100vw, 40vw`}
                  {...slide.image}
                />
              );
            })}
            <MobileSliderButtonR
              data-testid={`fade-slider-button-next-mobile`}
              onClick={() => {
                setUserInteraction(true);
                nextSlide(
                  activeSlide,
                  slides,
                  canClick,
                  setActiveSlide,
                  setCanClick,
                );
              }}
              title="Next Slide"
              aria-label="Next Slide"
            >
              <SliderControlsChevronRight />
            </MobileSliderButtonR>
          </ImageContainer>
        </FlexContainer>
      </Container>
    </Section>
  );
};
