'use client';

import { SearchAdvisor } from '../../advisor-search/SearchAdvisor';
import {
  AdvisorImage,
  CenteredContentInEllipsis,
  ConsultationLink,
  ExtendedEllipsisContainer,
  HorizontalContentContainer,
  HorizontalPageContainer,
  ImageBlock,
  ImageRow,
  QuoteAuthor,
  QuoteBlock,
  TextContainer,
  TitleHeader,
} from './HorizontalSearch.style';

const ImageData = [
  {
    src: 'https://cdn.thrivent.com/8b/fb/f6f69d3b4bb191472ae52fecc344/gettyimages-1141508257-1.png',
    alt: 'man smiling',
  },
  {
    src: 'https://cdn.thrivent.com/90/41/28134ef64ade955d27c572a84f0b/gettyimages-1171689561.png',
    alt: 'woman smiling',
  },
  {
    src: 'https://cdn.thrivent.com/c1/a2/30b476d84e699128c5157caf7640/gettyimages-1213961239.png',
    alt: 'man smiling',
  },
  {
    src: 'https://cdn.thrivent.com/d1/3a/aac6e5d74b07b9c7bfa20de220df/gettyimages-1179627340.png',
    alt: 'old man smiling',
  },
  {
    src: 'https://cdn.thrivent.com/ac/4c/f22dce1c4891a0e3ab24b998af2f/gettyimages-1191607527.png',
    alt: 'woman smiling',
  },
  {
    src: 'https://cdn.thrivent.com/85/1f/745b632a43d790324c2e9718bdc7/gettyimages-1171690168.png',
    alt: 'woman smiling',
  },
];

export const HorizontalSearch = () => {
  return (
    <HorizontalPageContainer>
      <HorizontalContentContainer>
        <TextContainer>
          <TitleHeader>
            Better money decisions start with a conversation
          </TitleHeader>
          <ConsultationLink>Schedule your free consultation</ConsultationLink>
        </TextContainer>
        <SearchAdvisor dropdown interactiveSearch />
      </HorizontalContentContainer>
      <ExtendedEllipsisContainer>
        <ImageRow>
          {ImageData &&
            ImageData.map((img) => (
              <ImageBlock key={img.src}>
                <AdvisorImage src={img.src} alt={img.alt} />
              </ImageBlock>
            ))}
        </ImageRow>
        <CenteredContentInEllipsis>
          <TextContainer>
            <QuoteBlock as="h2">
              It’s not just about retirement; it’s about relationships,
              opportunities and legacy. Can we identify what matters and align
              your financial resources to make it happen?
            </QuoteBlock>
            <QuoteAuthor>
              <b>Jon Skov</b>, Thrivent Wealth Advisor
            </QuoteAuthor>
          </TextContainer>
        </CenteredContentInEllipsis>
      </ExtendedEllipsisContainer>
    </HorizontalPageContainer>
  );
};
