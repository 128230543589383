import { SectionContainer, SectionRoot } from '@exploration/shared-components';
import {
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
} from '@mui/icons-material';
import { desktop, tablet } from '@thrivent/midwest-shared';
import { MDSAnchorButton, MDSText } from '@thrivent/midwest-web';
import NextImage from 'next/image';
import styled, { css } from 'styled-components';

// --------------------------------------------------------------------------

const MobileSliderButtonSize = 2.25;

// --------------------------------------------------------------------------

const SliderControlsChevronStyle = css`
  color: ${(p) => p.theme.midwestColors.textPrimary};
`;

const MobileSliderButtonStyle = css`
  position: absolute;
  top: calc(50% - (${MobileSliderButtonSize}rem / 2));
  z-index: 3;
  overflow: hidden;
  height: ${MobileSliderButtonSize}rem;
  width: ${MobileSliderButtonSize}rem;
  background-color: ${(p) => p.theme.midwestColors.backgroundPrimary};
  border-radius: 50%;
  border: none;
  padding: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  ${desktop} {
    display: none;
  }
`;

// --------------------------------------------------------------------------

export const Section = styled(SectionRoot)`
  background-color: ${(p) => p.theme.midwestColors.identityBrandTertiaryFaint};
  position: relative;
`;

export const Container = styled(SectionContainer)`
  padding-block: 4rem;
  ${desktop} {
    flex-direction: column;
    padding-block: 4rem 7.5rem;
  }
`;

export const STitle = styled(MDSText.HeroMd)`
  text-align: center;
  margin-top: 0;
  margin-bottom: 40px;
`;

export const FlexContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;
  ${desktop} {
    flex-direction: row;
    height: 31rem;
  }
`;

export const ImageSlider = styled.div``;

export const ImageContainer = styled.div`
  position: relative;
  overflow: hidden;
  flex: 1;
  min-height: 12.5rem;
  aspect-ratio: 2 / 1;
  @supports not (aspect-ratio: 2 / 1) {
    height: 12.5rem;
  }
`;

export const Image = styled(NextImage)`
  object-fit: cover;
  z-index: 1;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: opacity 0s 0.3s;
  &.active {
    opacity: 1;
    z-index: 2;

    @media (prefers-reduced-motion: no-preference) {
      transition: opacity 0.3s 0s;
    }
  }
`;

export const SliderContainer = styled.div`
  flex: 1;
  display: flex;
  background: ${(p) => p.theme.midwestColors.textSecondary};
  color: ${(p) => p.theme.midwestColors.textPrimaryDarkBG};
  padding: 1.5rem 2rem 2rem 2rem;
  ${desktop} {
    padding: 6.25rem 3.5rem 1.5rem 3.5rem;
    flex-direction: column;
    max-width: 31rem;
    justify-content: space-between;
  }
`;

export const SliderContentContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding: 0;
`;

export const SliderContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  pointer-events: none;
  transform: translateX(-2rem);
  transition:
    transform 0s 0.3s,
    opacity 0.3s 0s;
  z-index: 1;
  min-height: 100%;
  &.active {
    position: relative;
    opacity: 1;
    pointer-events: all;
    transform: translateX(0);
    z-index: 2;

    @media (prefers-reduced-motion: no-preference) {
      transition:
        transform 0.3s 0.3s,
        opacity 0.3s 0.3s;
    }
  }
`;

export const SlideTitle = styled(MDSText.HeroSm)`
  margin: 0;
`;

export const SlideDescription = styled(MDSText.BodyRegDefault)``;

export const SlideLink = styled(MDSAnchorButton)`
  width: 100%;
  text-decoration: none;
  margin-top: 0.5rem;

  ${tablet} {
    width: fit-content;
    display: inline-flex;
    margin-top: 1rem;

    :not(:last-of-type) {
      margin-right: 1rem;
    }
  }
`;

export const SlideLinkChevronRight = styled(ChevronRightIcon)`
  &.MuiSvgIcon-root {
    display: none;
  }
  ${desktop} {
    &.MuiSvgIcon-root {
      display: inline-block;
      font-size: 1.5em;

      @media (prefers-reduced-motion: no-preference) {
        transition: margin 0.3s ease-in-out;
      }
    }
  }
`;

export const SliderControls = styled(MDSText.BodyHeavyDefault)`
  display: none;
  ${desktop} {
    display: flex;
    gap: 1rem;
  }
`;

export const SliderChevronButton = styled.button`
  height: 2.25rem;
  width: 2.25rem;
  background-color: ${(p) => p.theme.midwestColors.backgroundPrimary};
  border-radius: 50%;
  display: flex;
  align-items: center;
  cursor: pointer;
  border: none;
`;

export const SliderControlsChevronRight = styled(ChevronRightIcon)`
  &.MuiSvgIcon-root {
    ${SliderControlsChevronStyle}
  }
`;

export const SliderControlsChevronLeft = styled(ChevronLeftIcon)`
  &.MuiSvgIcon-root {
    ${SliderControlsChevronStyle}
  }
`;

export const MobileSliderButtonL = styled.button`
  ${MobileSliderButtonStyle}
  left: 1rem;
`;

export const MobileSliderButtonR = styled.button`
  ${MobileSliderButtonStyle}
  right: 1rem;
`;
