'use client';

import { ConnectionOptionsSection } from '@exploration/shared-sections';
import {
  connectDataCallToAction,
  connectDataText,
} from '@thrivent-web/exploration/data';

// --------------------------------------------------------------------------

const sectionData = {
  headline: 'Two ways to schedule your free consultation',
  text: {
    ...connectDataText,
  },
  callToAction: {
    ...connectDataCallToAction,
  },
  image: {},
  video: {},
  showNewButtonColor: false,
};

// --------------------------------------------------------------------------

export const ConnectSection = () => (
  <ConnectionOptionsSection sectionData={sectionData} />
);
