'use client';

import {
  Container,
  SectionContainer,
  SectionRoot,
} from '@exploration/shared-components';
import {
  Root as uiTabs,
  List as uiTabsList,
  Trigger as uiTabsTrigger,
} from '@midwest/web/tabs';
import { desktop, tablet } from '@thrivent/midwest-shared';
import { MDSText } from '@thrivent/midwest-web';
import styled from 'styled-components';

// --------------------------------------------------------------------------

export const Tabs = styled(uiTabs)``;

export const MobileContainer = styled.div`
  ${desktop} {
    display: none;
  }
`;
export const ProductOfferingsSectionStyles = {
  Section: styled(SectionRoot)``,
  Container: styled(SectionContainer)`
    padding-block: 2rem;
    ${desktop} {
      flex-direction: column;
      padding-block: 4rem;
    }
  `,
  TextContainer: styled(Container)`
    text-align: left;
    grid-column: span 3;
    padding-inline: 0;

    ${tablet} {
      text-align: center;
    }

    ${desktop} {
      max-width: 36.75rem;
      margin-inline: auto;
    }
  `,

  Header: styled(MDSText.HeroMd)`
    grid-column: span 3;
  `,
  Description: styled(MDSText.BodyRegDefault)`
    grid-column: span 3;
  `,
};
export const TabsList = styled(uiTabsList)`
  flex-direction: column;
  gap: 0.5rem;

  ${tablet} {
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1rem;
  }

  ${desktop} {
    flex-wrap: nowrap;
    gap: 0;
  }
`;

export const TabsTrigger = styled(uiTabsTrigger)`
  background-color: ${(p) => p.theme.midwestColors.backgroundSecondary};
  color: ${(p) => p.theme.midwestColors.textLink};
  padding-block: 1rem;
  border-radius: 1rem;
  border: 1px solid ${(p) => p.theme.midwestColors.borderDefault};

  ${tablet} {
    flex: 1 0 40%;
  }

  ${desktop} {
    flex: 1;
    border-radius: 0;
    border: none;
    border-right: 1px solid ${(p) => p.theme.midwestColors.borderDefault};
    min-height: 3.25rem;
    min-width: 0;
    &:last-of-type {
      border-top-right-radius: 0.25rem;
      border-right: none;
    }
    &:first-of-type {
      border-top-left-radius: 0.25rem;
    }
  }

  &[data-state='active'],
  &:hover,
  &:focus {
    border: none;
    background-color: ${(p) => p.theme.midwestColors.neutralDarkHeavy};
    color: ${(p) => p.theme.midwestColors.neutralLightWeak};
  }
`;

export const TabText = styled(MDSText.BodyHeavyDefault)``;
