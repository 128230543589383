import {
  RecognitionBlock,
  SectionContainer,
  SectionRoot,
} from '@exploration/shared-components';
import { desktop } from '@thrivent/midwest-shared';
import { MDSText } from '@thrivent/midwest-web';
import styled from 'styled-components';

// --------------------------------------------------------------------------

export const RecognitionStyles = {
  Section: styled(SectionRoot)``,
  Container: styled(SectionContainer)`
    min-height: fit-content;
    gap: 1.5rem;
    ${desktop} {
      gap: 2.5rem;
      flex-direction: column;
    }
  `,
  GridAwardsCardRow: styled.div`
    display: grid;
    flex-direction: column;
    grid-template-columns: repeat(2, 50%);

    ${desktop} {
      gap: 1rem;
      flex-direction: row;
      grid-template-columns: repeat(4, 1fr);
    }
  `,
  RecognitionBlock: styled(RecognitionBlock)`
    border-right-width: 1px;
    border-top-width: 1px;
    border-style: solid;
    border-color: ${(p) => p.theme.midwestColors.identityBrandPrimaryWeak};
    border-bottom: none;
    border-left: none;

    &:nth-of-type(2n + 0) {
      border-right-width: 0;
    }
    &:nth-of-type(1),
    &:nth-of-type(2) {
      border-top-width: 0;
    }

    ${desktop} {
      border-top: none;
      border-right-width: 1px;

      &:nth-of-type(2n + 0) {
        border-right-width: 1px;
      }
      &:last-of-type {
        border-right: none;
      }
    }
  `,
  DisclaimerText: styled(MDSText.BodyRegXs)`
    display: block;
    margin: 0 auto;
    color: ${(p) => p.theme.midwestColors.neutralDarkMedium};

    a {
      font-size: 1em;
      text-decoration: underline;
    }
  `,
};
