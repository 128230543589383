import { ArrowRightIcon } from '../../svg';
import {
  ButtonLinkVideo as ExplorationButtonLinkVideo,
  Container as ExplorationContainer,
  SectionRoot,
} from '@exploration/shared-components';
import { ArrowRight, ChevronRight, MDSText } from '@midwest/web/base';
import { Link } from '@midwest/web/link';
import { desktop, tablet, textBodyRegDefault } from '@thrivent/midwest-shared';
import Image from 'next/image';
import styled from 'styled-components';

// --------------------------------------------------------------------------

export const Section = styled(SectionRoot)`
  background-color: ${(p) => p.theme.midwestColors.backgroundDisabled};

  ${desktop} {
    position: relative;
  }
`;

export const Container = styled(ExplorationContainer)`
  padding: 4.125rem 1rem 4rem;

  ${desktop} {
    padding: 4.125rem 1.5rem 6.25rem;

    position: relative;
  }
`;

export const ModalContainer = styled.div`
  ${desktop} {
    position: relative;
  }
`;

export const ArticleContainer = styled.div`
  z-index: 1;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 1.5rem;
  row-gap: 1.5rem;
  margin-top: 2.5rem;

  ${tablet} {
    position: relative;
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

export const ArticleImage = styled(Image)`
  object-fit: cover;
  transition: transform 300ms ease-in-out;
`;

export const ArticleButton = styled.div`
  padding: 0.5rem;
  color: ${(p) => p.theme.midwestColors.componentLinkDefault};
  appearance: none;
  border: none;
  width: 100%;
  background: none;
  text-align: left;
  justify-content: flex-start;
  align-items: center;
  display: flex;
  cursor: pointer;

  ${desktop} {
    padding: 1.5rem;
  }
`;

export const ArticleButtonText = styled(MDSText.BodyMediumXs)`
  ${tablet} {
    font-size: 0.875rem;
  }
  ${desktop} {
    font-size: 1rem;
  }
`;

export const Article = styled.button<{ modalActive: boolean }>`
  background-color: ${(p) => p.theme.midwestColors.backgroundDefault};
  border-radius: 8px;
  cursor: pointer;
  overflow: hidden;
  appearance: none;
  border: none;
  padding: 0;
  margin: 0;
  box-shadow: 4px 4px 24px 0px rgba(0, 0, 0, 0);
  opacity: ${(p) => (p.modalActive ? '.25' : '1')};
  transition:
    ${(p) => (p.modalActive ? 'opacity 300ms ease-in-out' : 'opacity 0ms')},
    box-shadow 300ms ease-in-out;

  &:hover,
  &:focus {
    box-shadow: 4px 4px 24px 0px rgba(0, 0, 0, 0.05);

    ${ArticleImage} {
      transform: scale(1.1);
    }
    ${ArticleButtonText} {
      font-weight: 700;
    }
  }
`;

export const ArticleImageContainer = styled.div`
  position: relative;
  height: 174px;
  background-color: ${(p) => p.theme.midwestColors.textTertiaryDarkBG};
  overflow: hidden;

  > img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
`;

export const ArticleButtonArrow = styled(ArrowRight)`
  height: 0.875rem;
  width: 0.875rem;
  fill: ${(p) => p.theme.midwestColors.componentLinkDefault};
  margin-left: 0.125rem;
`;

export const ModalCloseButton = styled.button`
  position: absolute;
  right: 1rem;
  top: 1rem;
  appearance: none;
  background: none;
  border: none;
  padding: 0;

  &:hover,
  &:focus {
    cursor: pointer;
  }
`;

export const ModalImageContainer = styled.div`
  position: relative;
  width: 20rem;
  height: 100%;
  background-color: ${(p) => p.theme.midwestColors.textTertiaryDarkBG};
  display: none;

  ${desktop} {
    display: block;
  }
`;

export const LifeMilestonesModalImageContainer = styled.div`
  position: relative;
  height: 100%;
  background-color: ${(p) => p.theme.midwestColors.textTertiaryDarkBG};
  display: none;

  ${desktop} {
    display: block;
    min-width: 20rem;
  }
`;

export const ModalImage = styled(Image)`
  object-fit: cover;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
`;

export const ModalTextContainer = styled.div`
  padding: 3.5rem 2.5rem 1rem;
  display: flex;
  opacity: 0;
  flex-direction: column;

  ${tablet} {
    flex-direction: row;
  }

  @keyframes fade-up {
    0% {
      opacity: 0;
      transform: translateY(1rem);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;

export const Modal = styled.dialog<{ open: boolean }>`
  position: fixed;
  z-index: 599;

  ${tablet} {
    position: absolute;
    z-index: 2;
  }

  top: 0;
  left: 0;
  background-color: ${(p) => p.theme.midwestColors.backgroundDefault};
  height: 100%;
  width: 100%;
  overflow: hidden;
  box-shadow: 4px 4px 24px 0px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  transform-origin: bottom;
  border: none;
  padding: 0;
  opacity: 0;
  pointer-events: none;
  display: none;

  @keyframes pop-in {
    0% {
      opacity: 0;
      transform: translateY(1rem) scale(0.9);
    }
    100% {
      opacity: 1;
      transform: translateY(0) scale(1);
    }
  }

  &.open {
    display: flex;
    pointer-events: all;
    @media (prefers-reduced-motion) {
      opacity: 1;
      transform: translateY(0) scale(1);
    }
    @media (prefers-reduced-motion: no-preference) {
      animation: pop-in 300ms ease-out 0ms;
      animation-fill-mode: forwards;
    }

    ${ModalTextContainer} {
      @media (prefers-reduced-motion) {
        opacity: 1;
        transform: translateY(0);
      }
      @media (prefers-reduced-motion: no-preference) {
        animation: fade-up 500ms ease-out 350ms;
        animation-fill-mode: forwards;
      }
    }
  }
`;

export const ModalTextMain = styled.div`
  ${tablet} {
    max-width: 27.5rem;
    padding-right: 1rem;
  }
`;

export const ModalTitle = styled(MDSText.TitleMd)``;

export const ModalDescription = styled(MDSText.BodyRegDefault)`
  margin-top: 0.75rem;
  color: ${(p) => p.theme.midwestColors.textSecondary};
`;

export const ModalListTitle = styled(MDSText.BodyHeavyDefault)`
  margin-top: 1.5rem;
`;

export const ModalList = styled.ul`
  padding: 0;
  list-style: none;
  margin-bottom: 3rem;
`;

export const ModalListItem = styled(MDSText.BodyRegSm)`
  color: ${(p) => p.theme.midwestColors.textSecondary};
  margin-top: 0.75rem;
  display: flex;
  align-items: center;
  sup {
    margin-top: -6px;
  }
  a {
    color: inherit;
    font-size: inherit;
    text-decoration: underline;
  }
  p {
    margin-right: 0.5rem;
    margin-bottom: auto;
    sup {
      vertical-align: top;
      position: relative;
      top: -0.5em;
    }
  }
`;

export const IndentedModalListItem = styled(MDSText.BodyRegSm)`
  padding-left: 1rem;
  color: ${(p) => p.theme.midwestColors.textSecondary};
  margin-top: 0.75rem;
  display: flex;
  align-items: center;
  sup {
    margin-top: -6px;
  }
  a {
    color: inherit;
    font-size: inherit;
    text-decoration: underline;
  }
`;

export const ModalListItemChevron = styled(ChevronRight)`
  fill: ${(p) => p.theme.midwestColors.componentLinkDefault};
  min-height: 1rem;
  min-width: 1rem;
  max-width: 1rem;
  max-height: 1rem;
  margin-right: 0.5rem;
`;
export const ModalTextSidebar = styled.div`
  margin-top: 2rem;
  ${tablet} {
    margin-top: 0;
  }
`;

export const ModalSidebarList = styled.ul`
  padding-left: 0;
`;

export const LinkContainer = styled.div`
  display: flex;
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-top: 1px solid ${(p) => p.theme.midwestColors.borderDisabled};
`;

export const ProductLink = styled(Link)`
  font-size: 0.75rem;
  font-weight: ${textBodyRegDefault.weight};
  margin-left: 0.5rem;
  display: flex;
  align-items: center;

  > svg:first-of-type {
    height: 2.25rem;
    width: 2.25rem;
    margin-right: 0.5rem;
    flex-shrink: 0;
  }
`;

export const ButtonLinkVideo = styled(ExplorationButtonLinkVideo)``;

export const VideoLink = styled.span`
  font-size: 0.75rem;
  font-weight: ${textBodyRegDefault.weight};
  margin-left: 0.5rem;
  color: ${(p) => p.theme.midwestColors.componentLinkDefault};
  display: flex;
  align-items: center;
  text-align: left;

  > svg:first-of-type {
    height: 2.25rem;
    width: 2.25rem;
    margin-right: 0.5rem;
    flex-shrink: 0;
  }

  &:hover,
  &:focus {
    color: ${(p) => p.theme.midwestColors.componentLinkHover};
  }
`;

export const LinkArrowContainer = styled.span`
  text-wrap: pretty;
`;

export const Arrow = styled(ArrowRightIcon)`
  vertical-align: middle;
  height: 0.675rem;
  width: 0.675rem;
  margin-left: 0.125rem;
  flex-shrink: 0;
`;

export const ClickOut = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  cursor: pointer;
`;

export const RelatedResourcesMilestones = styled(MDSText.BodyHeavyDefault)`
  padding-top: 2rem;
`;

export const LifeMilestonesModalList = styled.ul`
  padding: 0;
  list-style: none;
  margin-bottom: 1rem;
`;

export const LifeMilestonesOrderedModalList = styled.ol`
  padding: 0;
  list-style: none;
  margin-bottom: 1rem;
`;

export const LifeMilestonesModalTextMain = styled.div`
  ${tablet} {
    max-width: 27.5rem;
    margin-right: 4rem;
    width: 50%;
  }
`;

export const LifeMilestonesModalTextSidebar = styled.div`
  margin-top: 2rem;
  ${tablet} {
    margin-top: 0;
    width: 50%;
  }
`;

export const LifeMilestonesSidebarText = styled(MDSText.BodyMediumSm)`
  color: ${(p) => p.theme.midwestColors.textSecondary};
`;

export const LifeMilestonesListTitle = styled(MDSText.BodyHeavyDefault)`
  margin-top: 0.25rem;
  margin-bottom: 0.5rem;
`;
