'use client';

import { SectionIntro } from '@exploration/pages-shared';
import {
  RecognitionBlock,
  SectionContainer,
  SectionRoot,
} from '@exploration/shared-components';
import { desktop, tablet } from '@thrivent/midwest-shared';
import styled from 'styled-components';

// --------------------------------------------------------------------------

export const Section = styled(SectionRoot)`
  padding-top: 4rem;
  background-color: ${(p) => p.theme.midwestColors.backgroundDisabled};
`;

export const Container = styled(SectionContainer)`
  align-items: center;
  padding-bottom: 5rem;
  gap: 0.5rem;
  ${desktop} {
    flex-direction: column;
  }
`;

export const GenerosityRow = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 100%);
  justify-content: center;
  padding-top: 2rem;

  ${tablet} {
    grid-template-columns: repeat(3, 1fr);
  }
`;

export const GenerosityBlock = styled(RecognitionBlock)`
  align-items: center;
  div,
  p {
    height: 50%;
  }
  p {
    padding-top: 1rem;

    b {
      color: ${(p) => p.theme.midwestColors.textLink};
    }
  }

  ${tablet} {
    padding: 1rem;
  }

  ${desktop} {
    width: 100%;
    border-color: ${(p) => p.theme.midwestColors.borderDefault};
    border-style: solid;
    border-bottom: none;
    border-left: none;
    border-top: none;
    border-right-width: 1px;
    padding: 2rem;
    &:last-of-type {
      border-right: none;
    }
    p {
      min-width: 18rem;
    }
  }
`;

export const GenerositySectionIntro = styled(SectionIntro)`
  max-width: 36rem;
`;
