'use client';

import { Paper } from './paper';
import {
  Container,
  RootStyle,
  SectionContainer,
} from '@exploration/shared-components';
import { desktop } from '@thrivent/midwest-shared';
import { MDSAnchorButton, MDSText } from '@thrivent/midwest-web';
import styled from 'styled-components';

// --------------------------------------------------------------------------

export const LearnMoreStyles = {
  GridCardsRow: styled.div`
    display: grid;
    flex-direction: column;
    gap: 2rem;
    padding-block: 2rem;

    ${desktop} {
      flex-direction: row;
      grid-template-columns: repeat(3, 1fr);
    }
  `,

  RootContainer: styled.div`
    ${RootStyle}
  `,

  SectionContainer: SectionContainer,

  TextContainer: styled(Container)`
    text-align: center;
    padding-block-start: 2rem;
    max-width: 49.5rem;
  `,

  Title: styled(MDSText.HeroMd)`
    margin-top: 1rem;
  `,

  Description: styled(MDSText.BodyRegDefault)`
    margin-top: 1rem;
  `,
};

export const LearnMoreCardStyles = {
  CategoryText: styled(MDSText.BodyMediumXs)`
    color: ${(p) => p.theme.midwestColors.identityBrandPrimaryStrong};
    text-transform: uppercase;
  `,

  Paper: styled(Paper)`
    text-align: left;
    padding: 1.5rem;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
    background-color: ${(p) => p.theme.midwestColors.identityBrandPrimaryFaint};
    border: 1px solid ${(p) => p.theme.midwestColors.identityBrandPrimaryWeak};
  `,

  Title: styled(MDSText.HeroSm)`
    margin-top: 2.5rem;
  `,

  Description: styled(MDSText.BodyRegDefault)`
    margin-top: 0.75rem;
  `,

  AnchorButton: styled(MDSAnchorButton)`
    color: ${(p) => p.theme.midwestColors.identityBrandPrimaryStrong};
    background-color: ${(p) => p.theme.midwestColors.white};
    text-decoration: none;
    border: 2px solid ${(p) => p.theme.midwestColors.identityBrandPrimaryWeak};
    margin-top: 1rem;
    width: 100%;
    ${desktop} {
      width: auto;
    }
  `,
};
