const IconPeople = ({
  iconColor = '#2E66FF',
  ...props
}: {
  iconColor?: string;
}) => (
  <svg
    width="48"
    height="42"
    viewBox="0 0 48 42"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M27.7694 4.54474C27.7694 6.50332 26.1815 8.08948 24.2246 8.08948C22.266 8.08948 20.6782 6.50332 20.6782 4.54474C20.6782 2.58616 22.266 1 24.2246 1C26.1815 1 27.7694 2.58616 27.7694 4.54474Z"
      stroke={iconColor}
      strokeWidth="1.33333"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21.0987 41L20.4453 29.8969H14.8472C14.8472 29.8969 19.3729 25.5126 19.5125 13.8026C19.5125 13.8026 19.6987 11.7775 24.2244 11.7775C28.7501 11.7775 28.5622 13.8026 28.5622 13.8026C28.5622 13.8026 28.7036 27.378 33.4155 29.8969H27.1174L26.6502 41"
      stroke={iconColor}
      strokeWidth="1.33333"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.95771 13.8643C6.95771 14.985 6.04991 15.8928 4.92929 15.8928C3.80868 15.8928 2.90088 14.985 2.90088 13.8643C2.90088 12.7437 3.80868 11.8359 4.92929 11.8359C6.04991 11.8359 6.95771 12.7437 6.95771 13.8643Z"
      stroke={iconColor}
      strokeWidth="1.33333"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.64766 22.6043L8.42486 20.4578C8.42486 20.4578 8.48472 19.2807 7.78309 18.9C7.78309 18.9 7.04654 18.1867 4.85186 18.2266C2.65718 18.2665 1.65794 18.9216 1.39192 19.851L1.02448 25.4308C1.02448 25.4308 0.61713 26.8756 3.12605 27.4193L3.16096 34.946"
      stroke={iconColor}
      strokeWidth="1.33333"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.95703 34.9457V27.3125C6.95703 27.3125 7.67196 27.2577 8.24724 27.1596"
      stroke={iconColor}
      strokeWidth="1.33333"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M41.0049 13.8643C41.0049 14.985 41.9127 15.8928 43.0333 15.8928C44.1539 15.8928 45.0617 14.985 45.0617 13.8643C45.0617 12.7437 44.1539 11.8359 43.0333 11.8359C41.9127 11.8359 41.0049 12.7437 41.0049 13.8643Z"
      stroke={iconColor}
      strokeWidth="1.33333"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M39.3145 22.6043L39.5372 20.4578C39.5372 20.4578 39.4774 19.2807 40.179 18.9C40.179 18.9 40.9156 18.1867 43.1102 18.2266C45.3049 18.2665 46.3042 18.9216 46.5702 19.851L46.9376 25.4308C46.9376 25.4308 47.345 26.8756 44.8361 27.4193L44.8011 34.946"
      stroke={iconColor}
      strokeWidth="1.33333"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M41.0055 34.9457V27.3125C41.0055 27.3125 40.2906 27.2577 39.7153 27.1596"
      stroke={iconColor}
      strokeWidth="1.33333"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

// --------------------------------------------------------------------------

export default IconPeople;
