import SvgDedicatedPlanning from '../svg/svg-dedicated-planning';
import SvgManagedAccounts from '../svg/svg-managed-accounts';
import SvgMoneyCanvas from '../svg/svg-money-canvas';
import SvgProductSolutions from '../svg/svg-product-solutions';
import { ProductOfferingsTabStyles } from './tabs.style';
import { ProductContentTabPropsV2 } from './types';
import { ButtonLinkProps } from '@exploration/shared-types';
import { sanitize } from 'isomorphic-dompurify';
import ReactHtmlParser from 'react-html-parser';

// --------------------------------------------------------------------------

const {
  TabsContent,
  ContentContainer,
  ImageContainer,
  TextContainer,
  Title,
  Description,
  ContentLink,
  SubTitle,
  SmallDescription,
  Content,
  DisclaimerContainer,
  DisclaimerText,
} = ProductOfferingsTabStyles;

// --------------------------------------------------------------------------

export const SvgImages = {
  svgDedicatedPlanning: SvgDedicatedPlanning,
  svgManagedAccounts: SvgManagedAccounts,
  svgMoneyCanvas: SvgMoneyCanvas,
  svgProductSolutions: SvgProductSolutions,
};

export interface PoDisclaimer {
  text: string;
  buttonText?: string;
  buttonHref?: string;
  'aria-label'?: string;
}

export interface ProductOfferingTabPropsV2 {
  title: string;
  id: string;
  className?: string;
  financialAdvisorId?: string;
  svgImage: keyof typeof SvgImages;
  content: ProductContentTabPropsV2;
  link: ButtonLinkProps;
  disclaimer?: PoDisclaimer;
}

// --------------------------------------------------------------------------

const ProductOfferingsTab = ({
  id,
  link,
  className,
  content,
  svgImage,
  disclaimer,
}: ProductOfferingTabPropsV2) => {
  const SvgImage = SvgImages[svgImage] ?? SvgImages.svgMoneyCanvas;

  return (
    <TabsContent className={className} value={id}>
      <Content>
        <ContentContainer>
          {SvgImage && (
            <ImageContainer>
              <SvgImage />
            </ImageContainer>
          )}
          <TextContainer>
            <Title as="h2">{ReactHtmlParser(sanitize(content?.title))}</Title>
            <Description as={'p'}>
              {ReactHtmlParser(sanitize(content?.description))}
            </Description>
            <SubTitle as="h3">Ideal if you are</SubTitle>
            <SmallDescription as={'p'}>
              {ReactHtmlParser(sanitize(content?.idealForText))}
            </SmallDescription>
            {!!link && (
              <ContentLink
                variant="secondary"
                target="_blank"
                href={link.href ?? '#'}
                aria-label={link['aria-label']}
                id={link.id}
              >
                {link.text}
              </ContentLink>
            )}
          </TextContainer>
        </ContentContainer>
        {!!disclaimer && (
          <DisclaimerContainer>
            <DisclaimerText>
              {disclaimer.text}{' '}
              <a
                href={disclaimer.buttonHref}
                aria-label={disclaimer['aria-label']}
              >
                {disclaimer.buttonText}
              </a>
            </DisclaimerText>
          </DisclaimerContainer>
        )}
      </Content>
    </TabsContent>
  );
};

// --------------------------------------------------------------------------

export default ProductOfferingsTab;
