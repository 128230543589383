'use client';

import { SearchAdvisor } from '../advisor-search/SearchAdvisor';
import {
  Box,
  Container,
  Controls,
  RightSideBackground,
  Section,
  SubTitle,
  Title,
} from './ImageSearchHeroSection.style';

export const ImageSearchHeroSection = () => (
  <Section>
    <RightSideBackground
      src={
        'https://cdn.thrivent.com/eb/5c/e76daad84591883b7fbfa9468ebc/family-on-steps.jpeg'
      }
      alt="family on steps"
      fill
      style={{
        objectFit: 'cover',
      }}
    />
    <Container>
      <Box>
        <Title>Better money decisions start with a conversation</Title>
        <Controls>
          <SubTitle>Schedule your free consultation</SubTitle>
          <SearchAdvisor dropdown />
        </Controls>
      </Box>
    </Container>
  </Section>
);
