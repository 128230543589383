import { RecognitionBlockStyles } from './recognition-block.style';
import IconAwardDoc from './svg/icon-award-doc';
import IconPeople from './svg/icon-people';
import IconPieChart from './svg/icon-pie-chart';
import { sanitize } from 'isomorphic-dompurify';
import { ImageProps } from 'next/image';
import ReactHtmlParser from 'react-html-parser';

// --------------------------------------------------------------------------

const { Block, IconWrapper, ImageWrapper, Image, Text, Title } =
  RecognitionBlockStyles;

// --------------------------------------------------------------------------

const Icons = {
  iconAwardDoc: IconAwardDoc,
  iconPeople: IconPeople,
  iconPieChart: IconPieChart,
};

export interface RecognitionBlockProps {
  title?: string;
  description?: string;
  image?: ImageProps;
  className?: string;
  icon?: keyof typeof Icons;
}

// --------------------------------------------------------------------------

export const RecognitionBlock = ({
  className,
  image,
  title,
  description,
  icon,
}: RecognitionBlockProps) => {
  const Icon = icon ? Icons[icon] : null;
  return (
    <Block className={className}>
      {Icon && (
        <IconWrapper>
          <Icon />
        </IconWrapper>
      )}
      {!!image && (
        <ImageWrapper>
          <Image
            width={image.width}
            height={image.height}
            src={image.src}
            alt={image.alt}
          />
        </ImageWrapper>
      )}
      {!!title && <Title as={'h3'}>{ReactHtmlParser(sanitize(title))}</Title>}
      {!!description && (
        <Text as={'p'}>{ReactHtmlParser(sanitize(description))}</Text>
      )}
    </Block>
  );
};
