import { SearchAdvisor } from '../../advisor-search/SearchAdvisor';
import {
  ChevronRight,
  Container,
  Description,
  List,
  ListItem,
  Subtitle,
} from './Content.style';
import { NeedsBasedRecOption, needsBasedRecAtom } from './selection.atom';
import { MDSText } from '@thrivent/midwest-web';
import { useAtomValue } from 'jotai';

export type LeftContentType = {
  title: string;
  description: string;
  list: string[];
};

export interface LeftContentProps {
  leftContentData?: {
    [NeedsBasedRecOption.PERSONALIZED_FINANCIAL_PLANNING]: LeftContentType;
    [NeedsBasedRecOption.BUDGETING_AND_SAVING]: LeftContentType;
    [NeedsBasedRecOption.RETIREMENT_PLANNING]: LeftContentType;
    [NeedsBasedRecOption.INVESTING]: LeftContentType;
    [NeedsBasedRecOption.GENEROSITY_AND_GIVING]: LeftContentType;
    [NeedsBasedRecOption.GETTING_INSURED]: LeftContentType;
  };
}

// --------------------------------------------------------------------------

export const LeftContent = ({ leftContentData }: LeftContentProps) => {
  const needsBasedData = useAtomValue(needsBasedRecAtom);

  if (!needsBasedData.selectedOption || !leftContentData) {
    return null;
  }

  const selectedContent = leftContentData[needsBasedData.selectedOption];

  return (
    <Container>
      <MDSText.HeroMd as="h2">{selectedContent.title}</MDSText.HeroMd>
      <Description as="p">{selectedContent.description}</Description>
      <Subtitle as="h3">A financial advisor may talk to you about</Subtitle>
      <List data-testid="check-mark-list">
        {selectedContent.list.map((item, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <ListItem key={index}>
            <ChevronRight />
            <MDSText.BodyRegDefault>{item}</MDSText.BodyRegDefault>
          </ListItem>
        ))}
      </List>
      <SearchAdvisor dropdown buttonOnly />
    </Container>
  );
};
