'use client';

import {
  CTAWithImageStyles,
  CTAWithImageSection as ExplorationCTAWithImageSection,
} from '@exploration/shared-sections';
import { desktop } from '@thrivent/midwest-shared';
import { MDSAnchorButton, MDSText } from '@thrivent/midwest-web';
import styled from 'styled-components';

// --------------------------------------------------------------------------

export const Title = styled(MDSText.HeroMd)``;

export const Description = styled(MDSText.BodyRegDefault)``;

export const Content = styled.div`
  width: 100%;

  ${desktop} {
    max-width: 31rem;
  }
`;

export const Link = styled(MDSAnchorButton)`
  color: ${(p) => p.theme.midwestColors.textLink};
  border-color: ${(p) => p.theme.midwestColors.textLink};
  text-decoration: none;
  margin-top: 0.5rem;
  width: 100%;

  ${desktop} {
    width: fit-content;
  }
`;

export const CTAWithImageSection = styled(ExplorationCTAWithImageSection)`
  ${CTAWithImageStyles.ImageContainerBreakout} {
    ${desktop} {
      grid-column-end: main-left-stop;
    }
  }
  ${CTAWithImageStyles.Image} {
    object-position: right center;
  }
`;
