'use client';

import {
  Box,
  Button,
  Content,
  ContentWrapper,
  ImageStyle,
  ImageWrapper,
  List,
  ListItem,
  RightPanelContainer,
  TabContent,
  TabText,
  TabTextBold,
  TabTitle,
  WorkingWithUsSectionIntro,
} from './working-with-us-section.style';
import {
  ResponsiveContainer,
  SearchAdvisor,
  getOffsetTopRelativeToBody,
} from '@exploration/pages-shared';
import { useBreakpoint, useScrollY } from '@exploration/ui-utils';
import { explorationFeatureFlagAtom } from '@thrivent-web/exploration/feature-management';
import { useAtomValue } from 'jotai';
import { clamp } from 'ramda';
import React, { SyntheticEvent, useRef, useState } from 'react';

// --------------------------------------------------------------------------

export const WorkingWithUsSection = () => {
  const flags = useAtomValue(explorationFeatureFlagAtom);
  const isScrollEventsEnabled = flags['enable-working-with-us-scroll-events'];
  const [currentHeadingIndex, setCurrentHeadingIndex] = useState<number>(0);
  const scrollTriggerOverride = useRef<boolean>(false);

  function goToTab(e: SyntheticEvent, newIndex: number, title: string) {
    scrollTriggerOverride.current = true;
    setCurrentHeadingIndex(newIndex);
    history.replaceState(undefined, '', `#${title}`);
  }

  const containerRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const box0 = useRef<HTMLDivElement>(null);
  const box1 = useRef<HTMLDivElement>(null);
  const box2 = useRef<HTMLDivElement>(null);
  const allBoxes = [box0, box1, box2];

  if (isScrollEventsEnabled) {
    // blur the button of the tabs that are not selected
    // this is to prevent the focus from scrolling the page to the focused tab
    allBoxes.forEach((box, index) => {
      if (box.current) {
        const headingButton = box.current.querySelector('button');
        if (headingButton) {
          if (currentHeadingIndex !== index) {
            headingButton.blur();
          }
        }
      }
    });
  }

  const bp = useBreakpoint();
  const bpMobileOrTablet = bp === 'mobile' || bp === 'tablet';

  useScrollY((scrollY: number) => {
    if (!isScrollEventsEnabled) {
      return;
    }
    // When the user resumes scrolling, we need to reset the manual override
    if (scrollTriggerOverride.current) {
      scrollTriggerOverride.current = false;
      return;
    }

    //Disable the scroll trigger on mobile and tablet
    if (containerRef.current && contentRef.current && !bpMobileOrTablet) {
      const container = containerRef.current;
      const containerOffsetTop = getOffsetTopRelativeToBody(container);
      const contentFullHeight = contentRef.current.clientHeight;
      const scrolledIntoContainer = scrollY - containerOffsetTop;
      // The modifier is used to adjust the sensitivity of the scroll trigger
      const modifier = 5;

      const currentIndex = clamp(
        0,
        2,
        Math.floor(scrolledIntoContainer / (contentFullHeight / modifier)) + 1,
      );

      setCurrentHeadingIndex(currentIndex);
    }
  });

  return (
    <ResponsiveContainer ref={containerRef} maxWidth={1220}>
      <WorkingWithUsSectionIntro
        title="Working with us"
        description="Our financial advisors help you prioritize goals, establish a foundation, and track progress over time."
      />

      <Content ref={contentRef}>
        <List role="tablist" aria-label="Working with us Tabs">
          <ListItem
            ref={box0}
            role="tab"
            className={currentHeadingIndex === 0 ? 'active' : ''}
            aria-label={'Meet with a financial advisor that’s right for you'}
            aria-selected={currentHeadingIndex === 0}
            aria-expanded={currentHeadingIndex === 0}
            aria-controls={'meet-advisor-tab-content'}
          >
            <Button
              id={'meet-advisor-tab'}
              onClick={(e: SyntheticEvent) => goToTab(e, 0, 'meet-advisor-tab')}
            >
              <TabTitle>
                Meet with a financial advisor that’s right for you
              </TabTitle>
            </Button>
            <ContentWrapper
              className="animate__animated"
              data-state={currentHeadingIndex === 0 ? 'active' : 'inactive'}
              role="tabpanel"
              aria-labelledby="meet-advisor-tab"
              id="meet-advisor-tab-content"
            >
              <TabContent className="animate__animated">
                <TabText>
                  During your initial free meeting, we’ll get to know your
                  goals, financial situation, and what you value.
                </TabText>
              </TabContent>
            </ContentWrapper>
          </ListItem>

          <ListItem
            ref={box1}
            role="tab"
            className={currentHeadingIndex === 1 ? 'active' : ''}
            aria-label={'Create a financial plan'}
            aria-selected={currentHeadingIndex === 1}
            aria-controls={'financial-plan-tab-content'}
            aria-expanded={currentHeadingIndex === 1}
          >
            <Button
              id={'financial-plan-tab'}
              onClick={(e: SyntheticEvent) =>
                goToTab(e, 1, 'financial-plan-tab')
              }
            >
              <TabTitle>Create a financial plan</TabTitle>
            </Button>
            <ContentWrapper
              className="animate__animated"
              data-state={currentHeadingIndex === 1 ? 'active' : 'inactive'}
              role="tabpanel"
              aria-labelledby="financial-plan-tab"
              id="financial-plan-tab-content"
            >
              <TabContent className="animate__animated">
                <TabText>
                  We analyze your situation and deliver actionable steps you can
                  take toward your goals.
                </TabText>
              </TabContent>
            </ContentWrapper>
          </ListItem>
          <ListItem
            ref={box2}
            role="tab"
            aria-label={'Take action'}
            aria-expanded={currentHeadingIndex === 2}
            aria-selected={currentHeadingIndex === 2}
            aria-controls={'take-action-tab-content'}
            className={currentHeadingIndex === 2 ? 'active' : ''}
          >
            <Button
              id={'take-action-tab'}
              onClick={(e: SyntheticEvent) => goToTab(e, 2, 'take-action-tab')}
            >
              <TabTitle>Take action</TabTitle>
            </Button>
            <ContentWrapper
              className="animate__animated"
              data-state={currentHeadingIndex === 2 ? 'active' : 'inactive'}
              role="tabpanel"
              aria-labelledby="take-action-tab"
              id="take-action-tab-content"
            >
              <TabContent className="animate__animated">
                <TabText as="p">
                  We have options to support your financial plan and provide
                  ongoing guidance. As your life changes, we can work together
                  to help keep you on track.
                </TabText>
                <TabTextBold as="p">
                  Talk to a financial advisor to get started.
                </TabTextBold>
                <SearchAdvisor
                  id="take-action-tab-find-advisor-button"
                  aria-label="take-action-tab-find-advisor-button"
                  dropdown
                  buttonOnly
                />
              </TabContent>
            </ContentWrapper>
          </ListItem>
        </List>
        <RightPanelContainer>
          <Box
            className={`box ${currentHeadingIndex === 0 ? 'active' : ''}`}
            id="working-with-us-0"
          >
            <ImageWrapper className="animate__animated">
              <ImageStyle
                src="/connected-app.png"
                alt="Connected people"
                width={634}
                height={625}
                id="right-panel-image-0"
              />
            </ImageWrapper>
          </Box>
          <Box
            className={`box ${currentHeadingIndex === 1 ? 'active' : ''}`}
            id="working-with-us-1"
          >
            <ImageWrapper className="animate__animated">
              <ImageStyle
                src="/planning-dashboard-full.png"
                alt="Values and goals"
                width={683}
                height={364}
              />
            </ImageWrapper>
          </Box>
          <Box
            className={`box ${currentHeadingIndex === 2 ? 'active' : ''}`}
            id="working-with-us-2"
          >
            <ImageWrapper className="animate__animated">
              <ImageStyle
                src="/grandparent-full.png"
                alt="family enjoying time together"
                width={714}
                height={493}
              />
            </ImageWrapper>
          </Box>
        </RightPanelContainer>
      </Content>
    </ResponsiveContainer>
  );
};
