import { LearnMoreCardStyles } from './learn-mores.style';

// --------------------------------------------------------------------------

const { Paper, CategoryText, AnchorButton } = LearnMoreCardStyles;

// --------------------------------------------------------------------------

export type LearnMoreCardProps = {
  category: string;
  title: string;
  description: string;
  linkButtonTitle: string;
  linkButtonHref: string;
  linkButtonId: string;
  linkButtonAriaLabel?: string;
};

// --------------------------------------------------------------------------

const LearnMoreCard = (props: LearnMoreCardProps) => {
  return (
    <Paper>
      <div>
        <CategoryText>{props.category}</CategoryText>

        <LearnMoreCardStyles.Title as="h3">
          {props.title}
        </LearnMoreCardStyles.Title>
        <LearnMoreCardStyles.Description as={'p'}>
          {props.description}
        </LearnMoreCardStyles.Description>
      </div>
      <div>
        <AnchorButton
          href={props.linkButtonHref}
          variant="secondary"
          id={props.linkButtonId}
          aria-label={props.linkButtonTitle}
        >
          {props.linkButtonTitle}
        </AnchorButton>
      </div>
    </Paper>
  );
};

// --------------------------------------------------------------------------

export default LearnMoreCard;
