'use client';

import { SectionIntro } from '@exploration/pages-shared';
import { MDSButton } from '@midwest/web/button';
import { desktop, tablet } from '@thrivent/midwest-shared';
import { MDSText } from '@thrivent/midwest-web';
import Image from 'next/image';
import styled from 'styled-components';

// --------------------------------------------------------------------------

export const Content = styled.div`
  display: flex;
  gap: 1rem;
  padding-inline: 1rem;
  flex-direction: column;
  padding-bottom: 3rem;
  margin-top: 2rem;

  ${tablet} {
    padding-inline: 2rem;
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    gap: 1rem;
  }
  ${desktop} {
    grid-template-columns: repeat(12, 1fr);
  }
`;

export const TabTitle = styled(MDSText.TitleSm)``;
export const Button = styled(MDSButton)`
  background-color: transparent;
  margin: 0;
  padding: 0;
  color: #707575;
  min-width: auto;
  text-align: left;

  &:hover,
  &:focus {
    background-color: transparent;
    color: ${(p) => p.theme.midwestColors.textLink};
  }
`;

export const TabTextBold = styled(MDSText.BodyMediumDefault)`
  margin: 1.25rem 0;
  color: ${(p) => p.theme.midwestColors.textPrimary};
`;
export const ListItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  border-bottom: 1px solid ${(p) => p.theme.midwestColors.borderDisabled};
  padding-bottom: 1rem;
  ${tablet} {
    padding-bottom: 2rem;
  }
  cursor: pointer;
`;
export const TabText = styled(MDSText.BodyRegDefault)`
  color: ${(p) => p.theme.midwestColors.textPrimary};

  overflow: hidden;
`;
export const TabContent = styled.div`
  max-width: 31rem;
  overflow: hidden;
`;
export const ContentWrapper = styled.div`
  display: grid;
  grid-template-rows: 0fr;
  transition: grid-template-rows 0.5s ease-out;
  animation: fadeOut 0.5s;
`;

export const List = styled.div`
  align-self: center;
  ${tablet} {
    grid-column: 1 / span 4;
    gap: 2rem;
  }
  ${desktop} {
    grid-column: 1 / span 5;
  }
  display: flex;
  flex-direction: column;
  gap: 1rem;
  font-size: 16px;
  font-family: Inter, sans-serif;
  font-weight: 300;
  line-height: 24px;
  flex: 1;
  ${ListItem} {
    &:last-of-type {
      border-bottom: none;
    }
    &.active {
      ${Button} {
        color: ${(p) => p.theme.midwestColors.textLink};
      }

      ${ContentWrapper} {
        grid-template-rows: 1fr;
        animation: fadeIn 1.5s;
      }
    }
  }
`;
export const ImageWrapper = styled.div`
  animation:
    zoomOut 0.5s,
    fadeOut 2s;
`;
export const Box = styled.div`
  position: relative;

  ${ImageWrapper} {
    display: none;
  }

  &.active {
    ${ImageWrapper} {
      animation:
        zoomIn 0.5s,
        fadeIn 2s;
      display: block;
    }
  }
`;

export const RightPanelContainer = styled.div`
  position: relative;
  flex: 1;
  top: 0;
  aspect-ratio: 1/1;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
  ${tablet} {
    grid-column: 5 / span 4;
  }
  ${desktop} {
    grid-column: 7 / span 6;
  }
  ::after {
    background: #f7f9ff;
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    aspect-ratio: 1 / 1;
    content: ' ';
  }
`;

export const ImageStyle = styled(Image)`
  object-fit: contain;
  height: 100%;
  &#right-panel-image-0 {
    margin-left: 3%;
    margin-top: 1%;
    margin-right: 3%;
  }
`;

export const WorkingWithUsSectionIntro = styled(SectionIntro)`
  max-width: 36rem;
  margin-top: 4rem;
  ${desktop} {
    margin-top: 6rem;
  }
`;
