'use client';

import {
  ProductOfferingsSectionStyles,
  TabText,
  Tabs,
  TabsList,
  TabsTrigger,
} from './product-offerings.style';
import ProductOfferingsTab, { ProductOfferingTabPropsV2 } from './tabs/po-tab';
import { sanitize } from 'isomorphic-dompurify';
import { useState } from 'react';
import ReactHtmlParser from 'react-html-parser';

// --------------------------------------------------------------------------

const { Section, Container, TextContainer, Header, Description } =
  ProductOfferingsSectionStyles;

// --------------------------------------------------------------------------

export interface ProductOfferingsSectionProps {
  titleText: string;
  descriptionText?: string;
  tabContents: ProductOfferingTabPropsV2[];
}

// --------------------------------------------------------------------------

export const ProductOfferingsSection = ({
  titleText,
  descriptionText,
  tabContents,
}: ProductOfferingsSectionProps) => {
  const [tabValue, setTabValue] = useState(tabContents[0]?.id || '');
  return (
    <Section>
      <Container>
        <TextContainer>
          <Header as="h2">{titleText}</Header>
          <Description as={'p'}>{descriptionText}</Description>
        </TextContainer>
        <div>
          <Tabs
            defaultValue={tabContents[0]?.id || ''}
            onValueChange={setTabValue}
            value={tabValue}
          >
            <TabsList>
              {tabContents.map((tabContent) => (
                <TabsTrigger
                  key={tabContent.id}
                  value={tabContent.id}
                  className={tabContent.className}
                  aria-label={tabContent.id}
                >
                  <TabText>
                    {ReactHtmlParser(sanitize(tabContent.title))}
                  </TabText>
                </TabsTrigger>
              ))}
            </TabsList>
            {tabContents.map((tabContent) => {
              return (
                <ProductOfferingsTab {...tabContent} key={tabContent.id} />
              );
            })}
          </Tabs>
        </div>
      </Container>
    </Section>
  );
};
