'use client';

import { MDSText } from '@thrivent-web/exploration/brandRefreshTheme';
import { desktop, tablet } from '@thrivent/midwest-shared';
import Image from 'next/image';
import styled from 'styled-components';

export const HorizontalPageContainer = styled.div`
  width: 100%;
  overflow: hidden;
`;

export const HorizontalContentContainer = styled.div`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  margin-bottom: 0;
  max-width: 100%;
  ${tablet} {
    margin-top: 3rem;
    width: 90%;
    margin-bottom: 2rem;
  }
`;

export const ExtendedContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const CenteredContent = styled.div`
  width: 100%;
  text-align: center;
  margin-top: auto;
  background: ${(p) => p.theme.midwestColors.identityBrandPrimaryWeak};
  align-items: center;
`;

export const TextContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const SVGHeartContainer = styled.span`
  margin-left: 0.2rem;
  display: inline-flex;
  width: 0.75rem;

  svg {
    width: 100%;
    height: auto;
  }
  ${desktop} {
    margin-left: 0.6rem;
    width: 1.25rem;
  }
`;
export const TitleHeader = styled(MDSText.HeroXl)`
  text-align: center;
  font-size: 2.25rem;
  margin: 1rem auto;
  line-height: 2.75rem;
  color: ${(p) => p.theme.midwestColors.identityBrandPrimaryHeavy};
  width: 70%;
  ${tablet} {
    font-size: 2.5rem;
    line-height: 3.75rem;
  }

  ${desktop} {
    /* make a token if we use 4rem more often */
    font-size: 4rem;
    width: 61rem;
    line-height: 4.5rem;
  }
`;

export const ImageSection = styled.div`
  position: relative;
  width: 100%;
  ::after {
    content: '';
    display: block;
    position: absolute;
    background: ${(p) => p.theme.midwestColors.identityBrandPrimaryWeak};
    height: 55%;
    left: 0;
    bottom: 0;
    width: 100%;
    z-index: -1;
  }
`;

export const ImageRow = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 0 -10.4rem;
  z-index: 2;

  ${tablet} {
    margin: 0 0;
    padding: 0 5.25rem;
  }
`;

export const ImageBlock = styled.div`
  display: flex;
  z-index: 1;
  margin: 0.5rem 0.5rem 0 0.5rem;
  border-radius: 1rem;
  position: relative;
`;

export const AdvisorImage = styled(Image)`
  height: auto;
  z-index: 2;
  position: relative;
`;

export const ImageContainer = styled.div`
  border-radius: 1rem;
  align-self: flex-end;

  &:nth-of-type(3) {
    ${ImageBlock} {
      &:after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 80%;
        z-index: 1;
        box-shadow: 0px 8px 20px 0px
          ${(p) => p.theme.midwestColors.identityBrandPrimaryHeavy}33;
        border-radius: 16px;
      }
    }
  }
`;

export const QuoteBlock = styled(MDSText.HeroMd)`
  color: ${(p) => p.theme.midwestColors.identityBrandPrimaryDense};
  text-align: center;
  font-size: 1.25rem;
  width: 100%;
  max-width: 22.5rem;
  margin: 0 auto;
  margin-top: 2rem;
  line-height: 1.75rem;

  ${tablet} {
    margin-top: 1rem;
    max-width: 33rem;
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  ${desktop} {
    font-size: 2rem;
    max-width: 61.25rem;
    margin-top: 3rem;
    line-height: 2.75rem;
  }
`;

export const QuoteAuthor = styled(MDSText.BodyRegSm)`
  color: ${(p) => p.theme.midwestColors.identityBrandPrimaryDense};
  font-size: 1rem;
  font-weight: 400;
  line-height: 0.5rem;
  margin-top: 1.5rem;
  margin-bottom: 4rem;

  ${desktop} {
    margin-top: 2.5rem;
  }
`;
