import styled, { css } from 'styled-components';

export interface PaperProps extends React.ComponentPropsWithoutRef<'div'> {
  /** Sets the content. **Required** */
  children: React.ReactNode;
  /** Sets the `class` attribute. */
  className?: string | undefined;
  /** Sets the drop shadow. */
  shadow?: 'small-01' | 'small-02' | 'medium-01' | 'medium-02';
  /** Sets the theme colors. */
  secondary?: boolean;
}

const smallShadow01 = css`
  box-shadow: 0px 2px 6px rgb(21 21 21 / 0.15);
`;

const smallShadow02 = css`
  box-shadow: 0px 2px 6px rgb(21 21 21 / 0.35);
`;

const mediumShadow01 = css`
  box-shadow: 0px 3px 8px rgb(21 21 21 / 0.15);
`;

const mediumShadow02 = css`
  box-shadow: 0px 3px 8px rgb(21 21 21 / 0.3);
`;

const PaperContainer = styled.div<PaperProps>`
  background: ${(p) =>
    p.secondary
      ? p.theme.midwestColors.backgroundSecondary
      : p.theme.midwestColors.backgroundPrimary};

  ${(p) => {
    switch (p.shadow) {
      case 'small-01':
        return smallShadow01;
      case 'small-02':
        return smallShadow02;
      case 'medium-01':
        return mediumShadow01;
      case 'medium-02':
        return mediumShadow02;
      default:
        return;
    }
  }};
`;

/** @deprecated */
export default function Paper(props: PaperProps) {
  return <PaperContainer {...props}></PaperContainer>;
}
