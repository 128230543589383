'use client';

import { RecognitionSection } from '@exploration/shared-sections';
import { desktop } from '@thrivent/midwest-shared';
import styled from 'styled-components';

// --------------------------------------------------------------------------

export const StyledRecognitionSection = styled(RecognitionSection)`
  padding-block: 0rem 3rem;
  ${desktop} {
    padding-block: 2rem 7.5rem;
  }
`;
