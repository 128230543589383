'use client';

import { ChevronRight as ChevronRightIcon } from '@exploration/shared-components';
import { tablet } from '@thrivent/midwest-shared';
import { MDSAnchorButton } from '@thrivent/midwest-web';
import styled from 'styled-components';

// --------------------------------------------------------------------------

const Button = styled(MDSAnchorButton)`
  width: 100%;
  margin-top: 1.5rem;
  text-decoration: none;

  ${tablet} {
    width: auto;
    display: inline-flex;

    :not(:last-of-type) {
      margin-right: 1rem;
    }
  }
`;

export const HeroStyles = {
  Button: Button,
  SecondaryButton: styled(Button)`
    border-color: ${(p) => p.theme.midwestColors.textLink};
    color: ${(p) => p.theme.midwestColors.textLink};
  `,
  ChevronRight: styled(ChevronRightIcon)``,
};
