import { atom } from 'jotai';

export enum NeedsBasedRecOption {
  PERSONALIZED_FINANCIAL_PLANNING = 'PERSONALIZED_FINANCIAL_PLANNING',
  BUDGETING_AND_SAVING = 'BUDGETING_AND_SAVING',
  RETIREMENT_PLANNING = 'RETIREMENT_PLANNING',
  INVESTING = 'INVESTING',
  GETTING_INSURED = 'GETTING_INSURED',
  GENEROSITY_AND_GIVING = 'GENEROSITY_AND_GIVING',
}

export type NeedsBasedRecData = {
  selectedOption: NeedsBasedRecOption;
};

export const needsBasedRecAtom = atom<NeedsBasedRecData>({
  selectedOption: NeedsBasedRecOption.PERSONALIZED_FINANCIAL_PLANNING,
});
