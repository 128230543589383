'use client';

import {
  ModalCardSection,
  ModalCardSectionCard,
} from '@exploration/pages-shared';
import { MDSAnchorButton } from '@midwest/web/button';
import { tablet } from '@thrivent/midwest-shared';
import styled from 'styled-components';

// --------------------------------------------------------------------------

const milestoneData = {
  title: 'How we can help',
  description: `Life brings opportunities and challenges that can impact your financial goals. We help guide you through important money decisions, so you can stay focused on what matters most to you.`,
  cards: [
    {
      id: 'marriage-and-partnering',
      image: {
        src: 'https://cdn.thrivent.com/06/b1/a94bf513446a802dd4ff7590e0b3/couple-taking-selfie-small.png',
        alt: 'Woman behind counter',
        height: 261,
        width: 526,
      },
      modalImage: {
        src: 'https://cdn.thrivent.com/c9/03/66900fbc4b4b98f4d62f8b49118c/couple-taking-selfie-rectangle.png',
        alt: 'Woman behind counter',
        height: 720,
        width: 467,
      },
      title: 'Marriage and partnering',
      description:
        'Money can be a stressful topic for couples, but it doesn’t have to be. With the right guidance, money can become a means to living a meaningful life together. ',
      sidebarTitle: 'Related resources',
      sidebarLinks: [
        {
          icon: 'document',
          text: 'How to talk about money with your partner',
          type: 'link',
          href: ' https://www.thrivent.com/insights/budgeting-saving/how-to-talk-about-money-with-your-partner-5-tips-for-better-communication',
        },
        {
          icon: 'chart',
          text: 'Try our free money coaching service',
          type: 'link',
          href: 'https://www.thrivent.com/generosity/money-canvas',
        },
      ],
      listTitle: 'Prepare to talk with a financial advisor',
      listItems: [
        'Are you more inclined to save or spend?',
        'What’s one thing you wish your spouse knew about your views on money?',
        'What’s the top item you’re saving for right now?',
      ],
      listDescription:
        'Reflect on how you feel about money and discuss your responses together. ',
      listClosing:
        'It’s ok if you and your partner think and feel differently about money. When you meet, your financial advisor will guide the conversation and work with you both to understand your situation and create a financial plan that helps you achieve your goals, together. ',
    },
    {
      id: 'planning-for-college',
      image: {
        src: 'https://cdn.thrivent.com/58/11/dbb0cddf4a48a2c4207170da8aa6/mother-holding-daughter-small.png',
        alt: 'Woman behind counter',
        height: 261,
        width: 526,
      },
      modalImage: {
        src: 'https://cdn.thrivent.com/5f/d3/6bc602594f61a2a6fde8bef17061/mother-holding-daughter-rectangle.png',
        alt: 'Woman behind counter',
        height: 720,
        width: 467,
      },
      title: 'Planning for college',
      description:
        'Considering how you want to help your children with a college education can be an important part of your financial plan. What kind of support you decide on depends on many factors, including your values, other financial goals, and understanding your children’s educational aspirations.',
      sidebarTitle: 'Related resources',
      sidebarLinks: [
        {
          icon: 'document',
          text: 'Planning for how much to save for college',
          type: 'link',
          href: 'https://www.thrivent.com/insights/college-planning/planning-for-how-much-to-save-for-college',
        },
        {
          icon: 'chart',
          text: 'Try our free money coaching service',
          type: 'link',
          href: 'https://www.thrivent.com/generosity/money-canvas',
        },
      ],
      listTitle: 'Prepare to talk with a financial advisor',
      listItems: [
        'Where does education fit with other financial goals?',
        'How much support would you like to provide for your children’s education?',
      ],
      listDescription:
        'Reflect on how paying for your children’s college impacts your other financial goals.',
      listClosing:
        'There are many options for you to help with your children’s education. When you meet, your financial advisor will guide the conversation and help you determine how this milestone fits into your financial plan and what your next steps may be. ',
    },
    {
      id: 'buying-a-home',
      image: {
        src: 'https://cdn.thrivent.com/34/ca/f7b608a24260b2f45a5bfbc51b0e/parents-painting-wall-with-child-small.png',
        alt: 'Woman behind counter',
        height: 261,
        width: 526,
      },
      modalImage: {
        src: 'https://cdn.thrivent.com/d3/6a/b1bc350b4ac48e7d5a45736cb835/parents-painting-wall-with-child-rectangle.png',
        alt: 'Woman behind counter',
        height: 720,
        width: 467,
      },
      title: 'Buying a home',
      description:
        'When you’re thinking about buying a new home, it’s important to understand how this decision may impact other financial goals. Talking with a financial advisor who understands your values and goals can help ensure you’re making the best decision possible.',
      sidebarTitle: 'Related resources',
      sidebarLinks: [
        {
          icon: 'document',
          text: 'Smart money moves when buying a house',
          type: 'link',
          href: 'https://www.thrivent.com/insights/budgeting-saving/smart-money-moves-when-buying-a-house',
        },
        {
          icon: 'chart',
          text: 'Try our free money coaching service',
          type: 'link',
          href: 'https://www.thrivent.com/generosity/money-canvas',
        },
      ],
      listTitle: 'Prepare to talk with a financial advisor',
      listItems: [
        'What are a couple ways a new home could enhance your life and wellbeing?',
        'What are your other financial goals?',
        'How would the timeline change for these goals?',
      ],
      listDescription:
        'Reflect on how buying a home affects your overall financial priorities. If you have a partner, discuss your responses together.',
      listClosing:
        'When you meet, your financial advisor will guide the conversation and help you determine how this milestone fits into your financial plan.',
    },
    {
      id: 'becoming-a-parent',
      image: {
        src: 'https://cdn.thrivent.com/d9/9a/c6b0ab0a4755a996a68e5d304fee/father-and-daughter-in-kitchen-small.png',
        alt: 'Woman behind counter',
        height: 261,
        width: 526,
      },
      modalImage: {
        src: 'https://cdn.thrivent.com/50/fa/fb38925b4391874d89dcfedde2a6/father-and-daughter-in-kitchen-rectangle.png',
        alt: 'Woman behind counter',
        height: 720,
        width: 467,
      },
      title: 'Becoming a parent',
      description:
        'Expecting or adopting a child is a joyous experience that can also be nerve-wracking. Having a conversation with a financial advisor can help you understand how parenthood may impact your financial goals, both now and in the future.',
      sidebarTitle: 'Related resources',
      sidebarLinks: [
        {
          icon: 'document',
          text: 'Get the financial checklist for new parents',
          type: 'link',
          href: 'https://www.thrivent.com/insights/financial-planning/a-7-point-financial-checklist-for-new-parents',
        },
        {
          icon: 'chart',
          text: 'Try our free money coaching service',
          type: 'link',
          href: 'https://www.thrivent.com/generosity/money-canvas',
        },
      ],
      listTitle: 'Prepare to talk with a financial advisor',
      listItems: [
        'Consider new one-time and ongoing expenses this milestone may bring.',
        'Although it may seem too early, consider how education expenses and savings may fit into your financial plan.',
      ],
      listDescription:
        'Reflect on how becoming a parent may shape your financial future. ',
      listClosing:
        'Becoming a parent is filled with both potential and uncertainty for the future. When you meet, your financial advisor will guide the conversation to help you make sense of the financial impacts of parenthood and create a financial plan that keeps you focused on what’s most important to you. ',
    },
    {
      id: 'managing-job-changes',
      image: {
        src: 'https://cdn.thrivent.com/58/9a/d77bc5014044a61f44d58853a07a/man-and-woman-talking-small.png',
        alt: 'Woman behind counter',
        height: 261,
        width: 526,
      },
      modalImage: {
        src: 'https://cdn.thrivent.com/bf/18/1cfdf9e34615bd0bd34d1d31df83/man-and-woman-talking-rectangle.png',
        alt: 'Woman behind counter',
        height: 720,
        width: 467,
      },
      title: 'Managing job changes',
      description:
        'Facing a job change and wondering how it might affect your finances? Talking to a financial advisor can help provide clarity on how it may influence your financial decisions now and in the future.',
      sidebarTitle: 'Related resources',
      sidebarLinks: [
        {
          icon: 'document',
          text: 'A financial checklist for when you’re laid off',
          type: 'link',
          href: 'https://www.thrivent.com/insights/financial-planning/what-to-do-when-you-get-laid-off',
        },
        {
          icon: 'chart',
          text: 'Try our free money coaching service',
          type: 'link',
          href: 'https://www.thrivent.com/generosity/money-canvas',
        },
      ],
      listTitle: 'Prepare to talk with a financial advisor',
      listItems: [
        'How does this change your financial goal priorities?',
        'How does this affect your savings? ',
      ],
      listDescription:
        'Reflect on how this change in income (increase or decrease) may impact your cashflow.',
      listClosing:
        'When you meet, your financial advisor will guide the conversation, uncover how potential changes in income and benefits may affect your financial plan, and help you understand possible next steps. ',
    },
    {
      id: 'navigating-divorce',
      image: {
        src: 'https://cdn.thrivent.com/01/99/7a0fd2ff4d00add94fbe4f8b3ce2/woman-on-computer-looking-at-forms-small.png',
        alt: 'Woman behind counter',
        height: 261,
        width: 526,
      },
      modalImage: {
        src: 'https://cdn.thrivent.com/a8/76/18cb5085434b9728c10910296d02/woman-on-computer-looking-at-forms-rectangle.png',
        alt: 'Woman behind counter',
        height: 720,
        width: 467,
      },
      title: 'Navigating Divorce',
      description:
        'If you’re feeling overwhelmed by the financial aspects of getting a divorce, you don’t have to navigate this challenge alone. We’re here to guide and support you in understanding how divorce may affect your finances.',
      sidebarTitle: 'Related resources',
      sidebarLinks: [
        {
          icon: 'document',
          text: 'Your guide to divorce navigation',
          type: 'link',
          href: 'https://www.thrivent.com/insights/financial-planning/navigating-divorce',
        },
        {
          icon: 'chart',
          text: 'Try our free money coaching service',
          type: 'link',
          href: 'https://www.thrivent.com/generosity/money-canvas',
        },
      ],
      listTitle: 'Prepare to talk with a financial advisor',
      multiListItems: [
        {
          mainBullet: 'Reflect on your relationship with money',
          subBullets: [
            'What emotions do you have about money?',
            'How do these emotions play into current financial decisions you have to make?',
          ],
        },
        {
          mainBullet:
            'If you’re unsure where to start or how to organize the changes to your finances, consider filling out our interactive guidebook, <a href="https://www.thrivent.com/insights/financial-planning/navigating-divorce">Navigating Divorce</a>.',
        },
        {
          mainBullet:
            'Meet with a financial advisor who holds the Certified Divorce Financial Analyst (CDFA) designation.<sup>2<sup> ',
        },
      ],
      listClosing:
        'When you’re not sure what to focus on, a financial advisor can help you understand what steps and actions to take now and what can wait.',
    },
  ] as ModalCardSectionCard[],
};

// --------------------------------------------------------------------------

const LifeMilestonesAnchorButton = styled(MDSAnchorButton)`
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: -3rem;
  height: 3rem;
  min-width: 100%;
  z-index: 600;

  ${tablet} {
    min-width: 14rem;
    z-index: 3;
  }
`;

const returnLifeMilestonesButton = () => {
  return (
    <LifeMilestonesAnchorButton href="https://local.thrivent.com">
      Start a conversation
    </LifeMilestonesAnchorButton>
  );
};

export const LifeMilestonesHowWeCanHelpSection = () => {
  return (
    <ModalCardSection
      title={milestoneData.title}
      description={milestoneData.description}
      cards={milestoneData.cards}
      belowCardContent={returnLifeMilestonesButton()}
      modalContent="lifeMilestones"
    />
  );
};
