'use client';

import { ResponsiveContainer } from '@exploration/pages-shared';
import { SectionIntro } from '@exploration/shared-components';
import { MDSButton } from '@midwest/web/button';
import { MDSText } from '@thrivent-web/exploration/brandRefreshTheme';
import { desktop, tablet } from '@thrivent/midwest-shared';
import Image from 'next/image';
import styled from 'styled-components';

// --------------------------------------------------------------------------

export const Content = styled(ResponsiveContainer)`
  display: flex;
  gap: 1rem;
  padding-inline: 1rem;
  flex-direction: column;
  padding-bottom: 3rem;
  ${tablet} {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    gap: 1rem;
  }
  ${desktop} {
    grid-template-columns: repeat(12, 1fr);
  }
`;

export const TabTitle = styled(MDSText.TitleSm)`
  font-size: 1.125rem;
  font-weight: 400;
  ${tablet} {
    font-size: 1.375rem;
  }
  &:hover,
  &:focus {
    font-weight: 700;
  }
`;
export const Button = styled(MDSButton)`
  background-color: transparent;
  margin: 0;
  padding: 0;
  color: ${(p) => p.theme.midwestColors.identityBrandPrimaryDense};
  min-width: auto;
  text-align: left;

  &:hover,
  &:focus {
    background-color: transparent;
  }
`;

export const TabTextBold = styled(MDSText.BodyMediumDefault)`
  margin: 1.25rem 0;
  font-weight: 700;
`;
export const ListItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  border-bottom: 1px solid
    ${(p) => p.theme.midwestColors.semanticColorSentimentInfoMedium};
  padding-bottom: 1rem;

  ${tablet} {
    padding-bottom: 2rem;
  }
  cursor: pointer;
`;
export const TabText = styled(MDSText.BodyRegDefault)`
  font-size: 1rem;
  font-weight: 400;
  color: ${(p) => p.theme.midwestColors.identityBrandPrimaryDense};
  overflow: hidden;
  ${tablet} {
    p {
      font-size: 1.125rem;
      font-weight: 400;
    }
  }
`;
export const TabContent = styled.div`
  max-width: 31rem;
  overflow: hidden;
`;
export const ContentWrapper = styled.div`
  display: grid;
  grid-template-rows: 0fr;
  transition: grid-template-rows 0.5s ease-out;
  animation: fadeOut 0.5s;
`;

export const List = styled.div`
  width: 101%;

  align-self: center;
  ${tablet} {
    grid-column: 1 / span 4;
    gap: 2rem;
  }
  ${desktop} {
    grid-column: 1 / span 5;
  }
  display: flex;
  flex-direction: column;
  gap: 1rem;
  flex: 1;

  ${ListItem} {
    &:last-of-type {
      border-bottom: none;
    }

    &.active {
      ${Button} {
        color: ${(p) => p.theme.midwestColors.sentimentNegativeStrong};
      }
      h2 {
        font-weight: 700;
      }

      ${ContentWrapper} {
        grid-template-rows: 1fr;
        animation: fadeIn 1.5s;
      }
    }

    &.inactive {
      ${Button} {
        &:hover,
        &:focus {
          text-decoration: underline;
        }
      }
    }
  }
`;
export const ImageWrapper = styled.div`
  animation:
    zoomOut 0.5s,
    fadeOut 2s;
`;
export const Box = styled.div`
  position: relative;

  ${ImageWrapper} {
    display: none;
  }

  &.active {
    ${ImageWrapper} {
      animation:
        zoomIn 0.5s,
        fadeIn 2s;
      display: block;
    }
  }
`;

export const RightPanelContainer = styled.div`
  position: relative;
  flex: 1;
  top: 0;
  aspect-ratio: 1/1;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0rem;
  background: transparent;
  ${tablet} {
    grid-column: 5 / span 4;
  }
  ${desktop} {
    grid-column: 6 / span 7;
  }
`;

export const ImageStyle = styled(Image)`
  object-fit: contain;
  height: 100%;
`;

export const WorkingWithUsSectionIntro = styled(SectionIntro)`
  p {
    font-size: 1.125rem;
    font-weight: 400;
  }
  h2 {
    font-size: 1.75rem;
    font-weight: 700;
  }

  padding-right: 1rem;
  padding-left: 1rem;
  color: ${(p) => p.theme.midwestColors.identityBrandPrimaryDense};
  ${tablet} {
    h2 {
      font-size: 2.25rem;
      font-weight: 700;
    }
    p {
      font-size: 1.25rem;
      font-weight: 400;
    }
  }
`;

export const WorkingWithUsSectionContainer = styled.div`
  width: 100%;
  overflow: hidden;
  background-color: ${(p) => p.theme.midwestColors.identityBrandPrimaryFaint};
  padding-top: 3rem;

  ${tablet} {
    padding-top: 4rem;
  }
`;
