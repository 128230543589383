'use client';

import { SectionIntro } from '../../SectionIntro';
import {
  Article,
  ArticleButton,
  ArticleButtonArrow,
  ArticleButtonText,
  ArticleContainer,
  ArticleImage,
  ArticleImageContainer,
  ClickOut,
  Container,
  ModalContainer,
  Section,
} from './ModalCardSection.styles';
import { ModalCardSectionProps } from './ModalCardSection.types';
import { ModalCardSectionModal } from './ModalCardSectionModal';
import { Fragment, isValidElement, useState } from 'react';

// --------------------------------------------------------------------------

export const ModalCardSection = ({
  className = '',
  title,
  description,
  cards,
  belowCardContent,
  modalContent,
}: ModalCardSectionProps) => {
  const [modalOpen, setModalOpen] = useState<string | null>(null);

  const setModalData = (id: string) => {
    setModalOpen(id);
  };

  const clearModalData = () => {
    setModalOpen(null);
  };

  return (
    <Section className={className}>
      {!!modalOpen && <ClickOut onClick={() => clearModalData()}></ClickOut>}
      <Container>
        <SectionIntro title={title} description={description} />
        <ModalContainer>
          <ArticleContainer>
            {cards?.map((card) => (
              <Fragment key={card.id}>
                <Article
                  onClick={() => setModalData(card.id)}
                  modalActive={!!modalOpen}
                  aria-disabled={!!modalOpen}
                  aria-hidden={!!modalOpen}
                  disabled={!!modalOpen}
                  data-analytics-id={`${card.id}-modal-open`}
                >
                  <ArticleImageContainer>
                    <ArticleImage
                      {...card.image}
                      height={undefined}
                      width={undefined}
                      fill
                    />
                  </ArticleImageContainer>
                  <ArticleButton key={card.id}>
                    <ArticleButtonText>{card.title}</ArticleButtonText>{' '}
                    <ArticleButtonArrow />
                  </ArticleButton>
                </Article>
                <ModalCardSectionModal
                  open={modalOpen === card.id}
                  card={card}
                  resetModal={() => clearModalData()}
                  modalContent={modalContent}
                />
              </Fragment>
            ))}
          </ArticleContainer>
        </ModalContainer>
      </Container>
      {isValidElement(belowCardContent) && !!modalOpen && belowCardContent}
    </Section>
  );
};
