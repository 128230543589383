'use client';

import {
  Video as ExplorationVideo,
  SectionContainer,
  SectionRoot,
} from '@exploration/shared-components';
import { desktop } from '@thrivent/midwest-shared';
import { MDSAnchorButton, MDSText } from '@thrivent/midwest-web';
import styled from 'styled-components';

// --------------------------------------------------------------------------

export const Section = styled(SectionRoot)`
  background-color: ${(p) => p.theme.midwestColors.neutralLightWeak};
  overflow: hidden;
  position: relative;
  padding: 4rem 0;
`;

export const Container = styled(SectionContainer)`
  ${desktop} {
    flex-direction: row-reverse;
    min-height: 31rem;
  }
`;

export const Title = styled(MDSText.HeroMd)`
  margin: 0;

  ${desktop} {
    display: block;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  padding: 1.5rem;
  background: ${(p) => p.theme.midwestColors.backgroundSecondary};

  ${desktop} {
    padding: 1.5rem 3.5rem;
    max-width: 31rem;
  }
`;

export const VideoContainer = styled.div`
  height: 100%;
`;

export const SubTitle = styled(MDSText.BodyRegDefault)`
  margin: 1rem 0 0;
`;

export const Description = styled(MDSText.BodyRegDefault)``;

export const Button = styled(MDSAnchorButton)`
  margin-top: 1.5rem;
  text-decoration: none;
  width: 100%;

  ${desktop} {
    width: fit-content;
  }
`;

export const VideoHolder = styled.div`
  aspect-ratio: 16 / 9;
  width: 100%;
  position: relative;
  overflow: hidden;

  ${desktop} {
    aspect-ratio: unset;
  }
`;

export const Video = styled(ExplorationVideo)`
  height: 100%;
  position: absolute;
`;
