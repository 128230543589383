'use client';

import { HeroStyles } from './hero-v2.style';
import SvgDashLine from './svg/svg-dash-line';
import { desktopBreakpoint } from '@thrivent/midwest-shared';
import { sanitize } from 'isomorphic-dompurify';
import { ImageProps } from 'next/image';
import ReactHtmlParser from 'react-html-parser';

// --------------------------------------------------------------------------

const {
  Section,
  Container,
  Title,
  ImageContainer,
  Image,
  Content,
  SubTitle,
  Description,
  SvgContainer,
} = HeroStyles;

// --------------------------------------------------------------------------

interface TextProps {
  children: React.ReactNode;
}
export interface HeroSectionV2Props {
  titleText: TextProps;
  subTitleText?: TextProps;
  descriptionText?: TextProps;
  image?: ImageProps;
  className?: string;
  actionComponent?: JSX.Element;
}

// --------------------------------------------------------------------------

export const HeroSectionV2 = ({
  titleText,
  descriptionText = { children: '' },
  subTitleText = { children: '' },
  image,
  className,
  actionComponent,
}: HeroSectionV2Props) => {
  return (
    <Section className={className}>
      <Container>
        <Content>
          <Title as="h1">
            {ReactHtmlParser(sanitize(titleText.children as string))}
          </Title>
          {!!subTitleText?.children && <SubTitle as={'p'} {...subTitleText} />}
          <Description as={'p'} {...descriptionText} />
          {!!actionComponent && actionComponent}
        </Content>
        <ImageContainer>
          {image && (
            <Image
              fill
              priority
              quality={100}
              sizes={`(max-width: ${desktopBreakpoint}px) 100vw, 60vw`}
              {...image}
            />
          )}
        </ImageContainer>
        <SvgContainer>
          <SvgDashLine />
        </SvgContainer>
      </Container>
    </Section>
  );
};
