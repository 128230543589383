'use client';

import {
  BreakoutSectionContainer,
  SectionRoot,
  breakoutSectionWidthBreakpoint,
  sectionContainerBreakpoint,
  sectionContainerMockPadding,
  sectionContainerWithPaddingBreakpoint,
} from '@exploration/shared-components';
import { desktop, system, tablet } from '@thrivent/midwest-shared';
import { MDSText } from '@thrivent/midwest-web';
import Image from 'next/image';
import styled from 'styled-components';

// --------------------------------------------------------------------------

export const HeroStyles = {
  Section: styled(SectionRoot)`
    background-color: ${(p) => p.theme.midwestColors.neutralLightMedium};
  `,

  Container: styled(BreakoutSectionContainer)`
    position: relative;
    overflow: hidden;

    ${tablet} {
      flex-direction: row;
      justify-content: space-between;
    }

    ${desktop} {
      padding-right: 0;
      padding-left: 0;
    }

    ${sectionContainerBreakpoint} {
      padding-left: 1rem;
    }

    ${sectionContainerWithPaddingBreakpoint} {
      padding-left: ${sectionContainerMockPadding};
    }

    ${breakoutSectionWidthBreakpoint} {
      padding-left: 24.5rem;
    }
  `,

  SvgContainer: styled.div`
    position: absolute;
    display: none;

    ${sectionContainerBreakpoint} {
      display: block;
      left: calc(${sectionContainerMockPadding} + 32rem);
      max-height: 100%;
    }

    ${breakoutSectionWidthBreakpoint} {
      left: 56.5rem;
    }
  `,

  ImageContainer: styled.div`
    position: relative;
    margin-top: 1rem;
    background: ${(p) => p.theme.midwestColors.neutralLightStrong};
    aspect-ratio: 1.5 / 1;
    margin-left: -1rem;
    margin-right: -1rem;

    ${tablet} {
      margin: 0;
      margin-right: -1rem;
      flex-grow: 0.5;
    }

    ${desktop} {
      height: 25rem;
      margin: 0;
      aspect-ratio: unset;
    }

    ${sectionContainerBreakpoint} {
      height: 37.75rem;
    }

    /* Because we need to perfectly align with the icon CTA, we need to
    Make sure image does not cause the text to shrink at all */
    @media screen and (min-width: 1300px) {
      flex-grow: 1;
    }
  `,

  Image: styled(Image)`
    object-fit: cover;
    height: 100%;
    width: 100%;
  `,

  Content: styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1rem;
    background: ${(p) => p.theme.midwestColors.neutralLightMedium};
    width: 100%;

    ${tablet} {
      padding: 3.75rem 1.5rem 2rem 1.5rem;
      max-width: 42rem;
      flex-shrink: 0;
    }

    ${desktop} {
      padding-left: 1rem;
    }

    ${sectionContainerBreakpoint} {
      padding-left: 0;
      padding-right: 6rem;
    }

    ${sectionContainerWithPaddingBreakpoint} {
      padding-left: 0;
    }
  `,

  Title: styled(MDSText.HeroXl)`
    margin: 2rem 0 0;

    > span {
      color: ${(p) => p.theme.midwestColors.identityBrandPrimaryMedium};
    }
    ${tablet} {
      margin: 0;
    }

    ${desktop} {
      font-size: ${system.size['xl']['03'].value}rem;
    }
  `,

  SubTitle: styled(MDSText.Subtitle)`
    margin: 1rem 0 0;
  `,

  Description: styled(MDSText.BodyRegDefault)``,
};
