import {
  Pill,
  Container as explorationContainer,
} from '@exploration/shared-components';
import { desktop, system } from '@thrivent/midwest-shared';
import { MDSText } from '@thrivent/midwest-web';
import styled from 'styled-components';

export const TopSectionStyles = {
  NBRContainerSection: styled.section`
    background-color: ${(p) =>
      p.theme.midwestColors.identityBrandTertiaryFaint};
  `,
  TopSection: styled.section`
    padding: 2rem 0 1rem;
    display: flex;
    flex-direction: column;
    text-align: center;
    background-color: #363636;
  `,
  TopContainer: styled(explorationContainer)`
    display: flex;
    flex-direction: column;
  `,
  TopTitle: styled(MDSText.HeroXl)`
    color: ${(p) => p.theme.midwestColors.textPrimaryDarkBG};
    max-width: 36rem;
    margin-left: auto;
    margin-right: auto;
  `,
  TopInquiry: styled(MDSText.BodyHeavySm)`
    margin-top: 3.5rem;
    color: ${(p) => p.theme.midwestColors.textPrimaryDarkBG};
  `,
};

export const PillsSectionStyles = {
  PillsSection: styled.section`
    padding-bottom: 0.75rem;
    display: flex;
    gap: 0.5rem;
    flex-wrap: nowrap;
    justify-content: center;
    text-align: center;
    position: relative;
    width: 100%;
    overflow: hidden;
  `,
  PillsBanner: styled.div`
    position: absolute;
    bottom: 5.5rem;
    height: 100%;
    background-color: #363636;
    border-radius: 0 0 50% 50%;
    width: 200%;

    ${desktop} {
      width: 105%;
      bottom: 4rem;
    }
  `,
  PillsContainer: styled(explorationContainer)`
    margin-top: 0.25rem;
    display: flex;
    justify-content: flex-start;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none;
    padding-bottom: 1.5rem;

    position: relative;

    &::-webkit-scrollbar {
      display: none;
    }

    ${desktop} {
      overflow-x: visible;
      justify-content: center;
      padding-bottom: 0.5rem;
    }
  `,
  Pill: styled(Pill)`
    .MuiChip-label {
      color: ${(p) => p.theme.midwestColors.black};
    }

    &.MuiChip-filled {
      border: 1px solid
        ${(p) =>
          p.theme.midwestColors
            .componentButtonColorBackgroundStrongSecondaryPressed};

      .MuiChip-label {
        color: ${(p) => p.theme.midwestColors.black};
        font-weight: ${system.fontWeight.primary.semi.value};

        @media (prefers-reduced-motion: no-preference) {
          transform: scale(1.05);
          transition: transform 0.2s;
        }
      }
    }

    &.MuiChip-root {
      margin-right: 1.5rem;
      margin-top: 0.5rem;
      padding: 3.5rem 1rem 4rem;
      min-width: 11rem;
      max-width: 11rem;
      background-color: ${(props) =>
        props.selected
          ? props.theme.midwestColors
              .componentButtonColorBackgroundStrongSecondaryPressed
          : props.theme.midwestColors.identityBrandTertiaryFaint};
      flex-shrink: 0;
      flex-grow: 1;

      &::after {
        display: ${(props) => (props.selected ? 'block' : 'none')};
        content: '';
        position: absolute;
        bottom: -1rem;
        left: 50%;
        margin-left: -1rem;
        width: 0;
        height: 0;
        border-left: 1rem solid transparent;
        border-right: 1rem solid transparent;
        border-top: 1rem solid
          ${(p) =>
            p.theme.midwestColors
              .componentButtonColorBackgroundStrongSecondaryPressed};

        ${desktop} {
          bottom: -1rem;
        }
      }

      &.MuiChip-clickable:hover,
      &.MuiChip-clickable:focus {
        background-color: ${(p) =>
          p.theme.midwestColors
            .componentButtonColorBackgroundStrongSecondaryPressed};

        border: 1px solid
          ${(p) =>
            p.theme.midwestColors
              .componentButtonColorBackgroundStrongSecondaryPressed};

        .MuiChip-label {
          color: ${(p) => p.theme.midwestColors.black};
        }
      }
    }

    &.MuiChip-outlined {
      border: 1px solid
        ${(p) =>
          p.theme.midwestColors
            .componentButtonColorBackgroundStrongSecondaryPressed};
    }
  `,
  PillsButton: styled.button<{ left?: boolean }>`
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(50%, -70%);
    width: 4rem;
    height: 4rem;
    background-color: ${(p) => p.theme.midwestColors.backgroundPrimary};
    border-radius: 50% 0 0 50%;
    border: none;
    cursor: pointer;
    z-index: 2;
    filter: drop-shadow(-0.25rem 0rem 0.5rem rgba(0, 0, 0, 0.05));

    & svg {
      transform: translateX(-0.75rem);
      width: 1.5rem;
      height: 1.5rem;
    }

    ${desktop} {
      display: none;
    }

    ${({ left }) => `
      ${
        left &&
        `
        border-radius: 0 50% 50% 0;
        transform: translate(-50%, -70%);
        left: 0;

        & svg {
          transform: translateX(1rem);
        }
      `
      }
    `}
  `,
  PillsButtonContainer: styled.div`
    display: flex;
    width: 100%;
  `,
};

export const ContentSectionStyles = {
  ContentSection: styled.section`
    display: flex;
    flex-direction: column;
    padding: 2rem 0;
    justify-content: center;
    ${desktop} {
      max-width: calc(1300px + 3rem);
      margin: auto;
      flex-direction: row;
      padding: 2rem 0 1rem 0;
    }
  `,
  ContentContainer: styled(explorationContainer)`
    margin: 0;
    ${desktop} {
      max-width: 36rem;
    }
  `,
  ContentSectionCard: styled.div`
    flex-grow: 1;
    padding: 0 0 2rem 0;

    ${desktop} {
      max-width: 36rem;
      padding-left: 4rem;
    }
  `,
};
