'use client';

import { SearchAdvisor } from '../../advisor-search/SearchAdvisor';
import { Chart, Document, PlayIcon } from '../../svg';
import { LifeMilestonesSectionModal } from './LifeMilestonesSectionModal';
import {
  Arrow,
  ButtonLinkVideo,
  LinkArrowContainer,
  LinkContainer,
  Modal,
  ModalCloseButton,
  ModalDescription,
  ModalImage,
  ModalImageContainer,
  ModalList,
  ModalListItem,
  ModalListItemChevron,
  ModalListTitle,
  ModalSidebarList,
  ModalTextContainer,
  ModalTextMain,
  ModalTextSidebar,
  ModalTitle,
  ProductLink,
  VideoLink,
} from './ModalCardSection.styles';
import { ModalCardSectionCard } from './ModalCardSection.types';
import { useBreakpoint } from '@exploration/ui-utils';
import { Portal } from '@radix-ui/react-portal';
import { Close, MDSText } from '@thrivent/midwest-web';
import { sanitize } from 'isomorphic-dompurify';
import { Fragment, useEffect, useRef } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { useTheme } from 'styled-components';

// --------------------------------------------------------------------------

const buildDefaultModal = (
  card: ModalCardSectionCard,
  resetModal: () => void,
  modalContent?: string,
) => {
  switch (modalContent) {
    case 'lifeMilestones':
      return <LifeMilestonesSectionModal card={card} resetModal={resetModal} />;
    default:
      return (
        <>
          <ModalImageContainer>
            <ModalImage
              {...card.modalImage}
              height={undefined}
              width={undefined}
              fill
            ></ModalImage>
          </ModalImageContainer>
          <ModalTextContainer>
            <ModalTextMain>
              <ModalTitle as="h3">{card.title}</ModalTitle>
              <ModalDescription as="p">
                {ReactHtmlParser(sanitize(card.description || ''))}
              </ModalDescription>
              {!!card?.listItems?.length && (
                <>
                  <ModalListTitle as="h4">{card.listTitle}</ModalListTitle>
                  <ModalList as="ul">
                    {card.listItems.map((listItem) => (
                      <ModalListItem key={listItem} as="li">
                        <ModalListItemChevron />
                        {ReactHtmlParser(sanitize(listItem || ''))}
                      </ModalListItem>
                    ))}
                  </ModalList>
                </>
              )}
              <SearchAdvisor
                id={`${card.id}-modal-main-link`}
                dropdown
                buttonOnly
              />
            </ModalTextMain>
            <ModalTextSidebar>
              {!!card?.sidebarTitle && !!card?.sidebarLinks?.length && (
                <MDSText.BodyHeavyDefault as="h4">
                  {card.sidebarTitle}
                </MDSText.BodyHeavyDefault>
              )}
              {!!card?.sidebarLinks?.length && (
                <ModalSidebarList>
                  {card.sidebarLinks.map((sidebarLink, i) => {
                    return (
                      <LinkContainer
                        key={sidebarLink.href ?? sidebarLink.videoId}
                      >
                        {sidebarLink.type === 'video' ? (
                          <ButtonLinkVideo
                            titleText={sidebarLink.text}
                            videoId={sidebarLink?.videoId ?? ''}
                            linkComponent={
                              <VideoLink
                                data-analytics-id={`${card.id}-modal-link-${i}`}
                              >
                                <PlayIcon />
                                <LinkArrowContainer>
                                  {sidebarLink.text}
                                  <Arrow />
                                </LinkArrowContainer>
                              </VideoLink>
                            }
                          />
                        ) : (
                          <ProductLink
                            href={sidebarLink.href}
                            type="navigational"
                            data-analytics-id={`${card.id}-modal-link-${i}`}
                            onClick={() => resetModal()}
                          >
                            {sidebarLink.icon === 'document' && <Document />}
                            {sidebarLink.icon === 'chart' && <Chart />}
                            <LinkArrowContainer onClick={() => resetModal()}>
                              {sidebarLink.text}
                              <Arrow />
                            </LinkArrowContainer>
                          </ProductLink>
                        )}
                      </LinkContainer>
                    );
                  })}
                </ModalSidebarList>
              )}
            </ModalTextSidebar>
          </ModalTextContainer>
        </>
      );
  }
};

// --------------------------------------------------------------------------

export const ModalCardSectionModal = ({
  className = '',
  card,
  resetModal,
  open,
  modalContent,
}: {
  className?: string;
  card: ModalCardSectionCard;
  resetModal: () => void;
  open: boolean;
  modalContent?: string;
}) => {
  const modalRef = useRef<HTMLDialogElement | null>(null);
  const bp = useBreakpoint();
  const { midwestColors: colors } = useTheme();

  useEffect(() => {
    if (open) {
      document.body.style.overflow = bp === 'tablet' ? 'hidden' : 'unset';
      modalRef?.current?.show();
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [bp, open]);

  const PortalMobile = bp === 'tablet' ? Portal : Fragment;

  return (
    <PortalMobile>
      <Modal
        className={`${className} ${open ? 'open' : ''}`}
        ref={modalRef}
        role="dialog"
        open={open}
        data-analytics-name={`${card.id}-modal`}
        onBlur={(e) => {
          if (
            e.relatedTarget !== e.currentTarget &&
            !e.currentTarget.contains(e.relatedTarget) &&
            document.hasFocus()
          ) {
            resetModal();
          }
        }}
      >
        {buildDefaultModal(card, resetModal, modalContent)}
        <ModalCloseButton
          onClick={() => resetModal()}
          aria-label="Close Modal"
          data-analytics-id={`${card.id}-modal-close`}
        >
          <Close fill={colors.black} />
        </ModalCloseButton>
      </Modal>
    </PortalMobile>
  );
};
