'use client';

import { HeroSectionV2, HeroSectionV2Props } from './hero-v2';
import { HeroStyles } from './hero.style';
import { ButtonLinkProps } from '@exploration/shared-types';

// --------------------------------------------------------------------------

const { Button, SecondaryButton, ChevronRight } = HeroStyles;

// --------------------------------------------------------------------------

export interface HeroSectionProps extends HeroSectionV2Props {
  buttons?: {
    advice: ButtonLinkProps;
    nbr: ButtonLinkProps;
  };
  dotcomButtonUpdate?: boolean;
}

// --------------------------------------------------------------------------

export const HeroSection = ({
  buttons,
  dotcomButtonUpdate,
  ...heroProps
}: HeroSectionProps) => {
  const highlightButtons = (buttons: {
    advice: ButtonLinkProps;
    nbr: ButtonLinkProps;
  }) => (
    <SecondaryButton {...buttons.advice} href="/advice" variant="secondary">
      {buttons?.advice.text}
      <ChevronRight $hideOnMobile={true} />
    </SecondaryButton>
  );

  const buttonsComponent =
    buttons && !!Object.keys(buttons).length ? (
      <div>
        {!dotcomButtonUpdate && (
          <Button {...buttons.nbr} href="/nh/needs-based-rec">
            {buttons.nbr.text}
            <ChevronRight $hideOnMobile={true} />
          </Button>
        )}
        {dotcomButtonUpdate && (
          <Button {...buttons.advice} href="https://local.thrivent.com">
            Find financial advisors
            <ChevronRight $hideOnMobile={true} />
          </Button>
        )}
        {!dotcomButtonUpdate && highlightButtons(buttons)}
      </div>
    ) : undefined;

  return <HeroSectionV2 {...heroProps} actionComponent={buttonsComponent} />;
};
