'use client';

import { MDSText } from '@midwest/web/base';
import { breakpoints } from '@thrivent/midwest-shared';
import Image from 'next/image';
import styled from 'styled-components';

export const Section = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 35rem;
  margin-bottom: 5rem;

  ${breakpoints.desktop} {
    height: 42rem;
    max-width: 1600px;
    margin-inline: auto;
    margin-bottom: 0;
  }
`;

export const RightSideBackground = styled(Image)`
  position: absolute;
  top: -10rem;
  z-index: -1;
  margin-top: -10%;

  ${breakpoints.desktop} {
    margin-top: 0;

    max-width: 80%;
    margin-left: 20%;
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }
`;

export const Box = styled.div`
  max-width: 36rem;
  background-color: ${(p) => p.theme.midwestColors.white};
  padding: 1rem;
  border-radius: 8px;
  box-shadow: 4px 4px 26px 16px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin-inline: 1rem;
  ${breakpoints.desktop} {
    padding: 2.5rem 2.5rem 2.5rem 0rem;
    box-shadow: none;
  }
`;

export const Controls = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const Container = styled.div`
  width: auto;

  margin: 0 auto;
  margin-top: 20rem;

  ${breakpoints.desktop} {
    width: 100%;
    max-width: 1200px;
    margin: 0px auto;
  }
`;

export const Title = styled(MDSText.HeroLg)`
  text-align: center;
  font-size: 2rem;
  line-height: 2.5rem;

  ${breakpoints.desktop} {
    text-align: left;
    font-size: 3.25rem;
    font-weight: 400;
    line-height: 4.25rem;
    display: block;
    padding-right: 1rem;
    padding-left: 0.5rem;
  }
`;
export const SubTitle = styled(MDSText.TitleSm)`
  color: ${(p) => p.theme.midwestColors.textTertiary};
  font-size: 1rem;
  padding-left: 0.5rem;
`;
