'use client';

import { ResponsiveContainer } from '@exploration/pages-shared';
import { SectionIntro } from '@exploration/shared-components';
import { MDSText } from '@thrivent-web/exploration/brandRefreshTheme';
import { desktop, tablet } from '@thrivent/midwest-shared';
import styled from 'styled-components';

export const Container = styled(ResponsiveContainer)`
  display: flex;
  flex-direction: column;
  text-align: center;
  margin: 0;
  padding: 3rem 1rem 3.5rem 1rem;

  ${tablet} {
    margin: 4rem auto 4.5rem;
    padding: 0;
  }
`;

export const Intro = styled(SectionIntro)`
  margin: 0 auto;
`;
export const StatsContainer = styled.div`
  display: flex;
  margin-top: 0;
  flex-direction: column;
  ${tablet} {
    margin-top: 2.25rem;
    flex-direction: row;
  }
`;

export const DataTitle = styled(MDSText.HeroXl)<{ hasSymbol: boolean }>`
  color: ${(p) => p.theme.midwestColors.semanticColorSentimentInfoHeavy};
  min-width: fit-content;
  background-image: linear-gradient(
    to top,
    ${(p) => p.theme.midwestColors.identityBrandPrimaryWeak} 40%,
    transparent 20%
  );
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  font-weight: 700;
  font-size: 4rem;
  sup {
    font-weight: 400;
    font-size: 50%;
    line-height: normal;
  }
`;

export const DataDescription = styled(MDSText.BodyRegDefault)`
  color: ${(p) => p.theme.midwestColors.identityBrandPrimaryDense} ${tablet} {
    margin-top: 1.25rem;
  }
  margin-top: 0.5rem;
  max-width: 20rem;

  ${tablet} {
    margin-top: 1rem;
    max-width: none;
    max-width: 14rem;
  }
  ${desktop} {
    max-width: 20rem;
  }
  sup {
    line-height: normal;
  }
`;

export const Data = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-basis: 100%;
  border-right: none;
  padding: 0;
  padding-top: 1rem;

  ${tablet} {
    flex-basis: 33%;

    border-right: 1px solid
      ${(p) => p.theme.midwestColors.identityBrandPrimaryWeak};

    &:last-of-type {
      border-right: none;
    }
  }
`;
