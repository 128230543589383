'use client';

import { Icon, svgIcons } from '@exploration/icons';
import { MDSLink } from '@midwest/web/link';
import { tablet } from '@thrivent/midwest-shared';
import { MDSText } from '@thrivent/midwest-web';
import { Fragment } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  ${tablet} {
    justify-content: space-between;
  }
`;

const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const IconContainer = styled.div`
  height: 7rem;
  width: 7rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -4rem;
  margin-bottom: 1rem;
  z-index: 1;
  background-color: #454d5e;
  border-radius: 50%;
  svg {
    path {
      fill: ${(p) => p.theme.midwestColors.identityBrandSecondary};
    }
  }
`;

const LinkContainer = styled.div`
  align-items: center;
`;

const MainDescription = styled(MDSText.BodyRegSm)`
  text-align: center;
  padding-top: 0.5rem;
  max-width: 23rem;
`;

const Description = styled(MainDescription)`
  padding: 0.5rem 1rem 0;
`;

const Title = styled(MDSText.BodyHeavyXs)`
  color: ${(p) => p.theme.midwestColors.textTertiary};
  text-align: center;
`;

const Value = styled(MDSText.DataSm)`
  color: ${(p) => p.theme.midwestColors.identityBrandPrimaryStrong};
  text-align: center;
`;

const Divider = styled.hr`
  border: none;
  border-top: 1px solid ${(p) => p.theme.midwestColors.textSecondaryDarkBG};
  margin: 1rem 0;
  width: 100%;
`;

const Link = styled(MDSLink)`
  span {
    font-size: 1.25rem;
    font-weight: 700;
  }
  svg {
    width: 1.5rem;
    height: 1.5rem;
    margin-bottom: 0.25rem;
  }
`;

export interface IconCardProps {
  icon?: svgIcons;
  headline?: string;
  subHeadline?: string;
  link?: string;
  buttonId?: string;
  ariaId?: string;

  content?: {
    title: string;
    subtitle: string;
  }[];
}

const formatFont = (value: string) => {
  return value.split('$').map((part, i) =>
    i === 0 ? (
      part
    ) : (
      <Fragment key={part}>
        <span style={{ fontSize: '0.5em', verticalAlign: 'super' }}>$</span>
        {part}
      </Fragment>
    ),
  );
};
// --------------------------------------------------------------------------

export const IconCard = ({
  icon,
  headline,
  subHeadline,
  link,
  buttonId,
  ariaId,
  content,
}: IconCardProps) => {
  return (
    <Container>
      <IconContainer>{!!icon && <Icon icon={icon} />}</IconContainer>
      <LinkContainer>
        <Link
          id={buttonId}
          href={link ?? '#'}
          type="navigational"
          aria-label={ariaId}
        >
          {headline}
        </Link>
      </LinkContainer>
      <Content>
        <MainDescription>{subHeadline}</MainDescription>
        <Divider />
        {content &&
          content.map((item, index) => {
            const isLastItem = index === content.length - 1;
            return (
              <Fragment key={item.title}>
                <Title>{item.title}</Title>
                {isLastItem ? (
                  <Description>{formatFont(item.subtitle)}</Description>
                ) : (
                  <Value>{formatFont(item.subtitle)}</Value>
                )}
                {!isLastItem && <Divider />}
              </Fragment>
            );
          })}
      </Content>
    </Container>
  );
};
