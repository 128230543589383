'use client';

import * as RadixTabs from '@radix-ui/react-tabs';
import {
  textBodyHeavyDefault,
  textBodyHeavySm,
} from '@thrivent/midwest-shared';
import styled from 'styled-components';

export const Root = styled(RadixTabs.Root)`
  display: flex;
  align-items: stretch;
  flex-direction: column;
  background-color: ${(p) => p.theme.midwestColors.neutralLightWeak};
`;

export const Trigger = styled(RadixTabs.Trigger)`
  cursor: pointer;
  flex-grow: 1;
  background-color: transparent;
  border-radius: 0%;
  border: none;
  color: ${(p) => p.theme.midwestColors.textSecondary};
  font-family: ${textBodyHeavySm.font};
  font-weight: ${textBodyHeavySm.weight};
  font-size: ${textBodyHeavySm.rem}rem;
  line-height: ${textBodyHeavySm.lineHeight}px;
  padding: ${(p) => p.theme.spacing.md};
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px -${(p) => p.theme.border.widthDefault} 0px 0px #f1f2f2 inset;
  /* stylelint-disable-next-line */
  &:hover {
    background-color: ${(p) => p.theme.midwestColors.backgroundHover};
  }
  ${(p) => p.theme.breakpoints.desktop} {
    font-family: ${textBodyHeavyDefault.font};
    font-weight: ${textBodyHeavyDefault.weight};
    font-size: ${textBodyHeavyDefault.rem}rem;
    line-height: ${textBodyHeavyDefault.lineHeight}px;
    box-shadow: none;
  }
  &[data-state='active'] {
    box-shadow: 0px -${(p) => p.theme.border.widthFocused} 0px 0px
      ${(p) => p.theme.midwestColors.identityBrandPrimaryMedium} inset;
    color: ${(p) => p.theme.midwestColors.identityBrandPrimaryMedium};
    fill: ${(p) => p.theme.midwestColors.identityBrandPrimaryMedium};
    &:hover,
    &:focus {
      color: ${(p) => p.theme.midwestColors.identityBrandPrimaryStrong};
      fill: ${(p) => p.theme.midwestColors.identityBrandPrimaryStrong};
      background-color: ${(p) => p.theme.midwestColors.backgroundHover};
      box-shadow: 0px -${(p) => p.theme.border.widthFocused} 0px 0px
        ${(p) => p.theme.midwestColors.identityBrandPrimaryStrong} inset;
    }

    &:focus-visible {
      box-shadow: inset 0px 0px 0px ${(p) => p.theme.border.widthFocused}
        ${(p) => p.theme.midwestColors.borderFocus};
      outline: none;
      /* background-color: ${(p) => p.theme.midwestColors.neutralLightWeak}; */
    }
  }
`;

export const List = styled(RadixTabs.List)`
  display: flex;
  flex-direction: row;
`;

export const Content = styled(RadixTabs.Content)``;

export const MDSTabs = {
  Root,
  List,
  Content,
  Trigger,
};
