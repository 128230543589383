const SvgDashLine = () => (
  <svg
    width="280"
    // height="640"
    viewBox="0 0 283 612"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.00007 0L1.00001 185.311C1.00001 190.834 5.47716 195.311 11 195.311L272 195.311C277.523 195.311 282 199.788 282 205.311L282 640"
      stroke="#2E66FF"
      strokeLinejoin="round"
      strokeDasharray="8 8"
    />
  </svg>
);

// --------------------------------------------------------------------------

export default SvgDashLine;
