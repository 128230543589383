'use client';

import {
  FormContainer,
  Input,
  SearchButton,
  SearchIcon,
} from './advisor-search-input.style';
import { ButtonLinkProps } from '@exploration/shared-types';
import { useState } from 'react';

export interface AdvisorSearchInputProps {
  className?: string;
  link?: ButtonLinkProps;

  analyticsId?: string;
}

export const AdvisorSearchInput = ({
  link,
  analyticsId,
}: AdvisorSearchInputProps) => {
  const [inputValue, setInputValue] = useState('');

  const goToAdvisorPage = (url = '') => {
    let value = '';
    if (inputValue) {
      value = `?qp=${inputValue}`;
    }

    // normally you would use `const { push } = useRouter()` (or the app router
    // equivalent) for this, but we're utilizing a more basic approach to
    // support both systems
    window.location.href = `${url}${value}`;
  };
  return (
    <FormContainer>
      <Input
        name="advisor-search-input"
        label="Find a financial advisor"
        placeholder="Enter city, state, or ZIP code"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          setInputValue(e.currentTarget.value)
        }
      />
      {link && (
        <SearchButton
          role="link"
          id={`${analyticsId}-search`}
          data-analytics-id={`${analyticsId}-search`}
          aria-label={link['aria-label']}
          onClick={() => goToAdvisorPage(link.href)}
        >
          {link.text ?? 'Search'} <SearchIcon />
        </SearchButton>
      )}
    </FormContainer>
  );
};
