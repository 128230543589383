import { MDSText } from '@thrivent/midwest-web';
import Image from 'next/image';
import styled from 'styled-components';

// --------------------------------------------------------------------------

export const RecognitionBlockStyles = {
  Block: styled.div`
    text-align: center;
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
  `,
  IconWrapper: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
  `,
  ImageWrapper: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
  `,
  Image: styled(Image)`
    height: auto;
  `,
  Text: styled(MDSText.BodyRegDefault)`
    line-height: 1.5rem;
    & sup {
      line-height: 0.25rem;
    }
  `,
  Title: styled(MDSText.TitleSm)`
    min-width: 20rem;
  `,
};
