import { DisclaimerSection } from '../disclaimer/disclaimer-section';
import { RecognitionStyles } from './recognition-section.style';
import { RecognitionBlockProps } from '@exploration/shared-components';

// --------------------------------------------------------------------------

const { Section, Container, GridAwardsCardRow, RecognitionBlock } =
  RecognitionStyles;

// --------------------------------------------------------------------------

export type RecognitionSectionProps = {
  blocks?: RecognitionBlockProps[];
  disclaimerText?: string;
  className?: string;
  id?: string;
};

// --------------------------------------------------------------------------

export const RecognitionSection = ({
  blocks,
  disclaimerText,
  className,
  id,
}: RecognitionSectionProps) => {
  if (!Array.isArray(blocks)) {
    return null;
  }
  return (
    <Section id={id} className={className}>
      <Container>
        <GridAwardsCardRow>
          {blocks.map((block) => (
            <RecognitionBlock
              key={block?.icon ?? block?.description ?? block?.image?.alt}
              {...block}
            />
          ))}
        </GridAwardsCardRow>
        <DisclaimerSection
          disclaimers={disclaimerText ? [disclaimerText] : undefined}
        ></DisclaimerSection>
      </Container>
    </Section>
  );
};
