import { Container } from '@exploration/shared-components';
import { Content as uiTabsContent } from '@midwest/web/tabs';
import { desktop, tablet } from '@thrivent/midwest-shared';
import { MDSAnchorButton, MDSText } from '@thrivent/midwest-web';
import nextImage from 'next/image';
import styled from 'styled-components';

// --------------------------------------------------------------------------

export const TabsContent = styled(uiTabsContent)`
  padding-top: 1.5rem;
  ${tablet} {
    padding-top: 3rem;
  }
`;

export const ProductOfferingsTabStyles = {
  TabsContent,
  Content: styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    ${tablet} {
      min-height: 390px;
    }
  `,
  ContentContainer: styled.div`
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    ${desktop} {
      flex-direction: row;
      gap: 7.75rem;
    }
  `,
  ImageContainer: styled.div`
    display: flex;
    justify-content: center;

    ${desktop} {
      flex: 1 0;
      width: 38.25rem;
    }
  `,
  Image: styled(nextImage)`
    max-width: 100%;
    height: 200px;
  `,

  TextContainer: styled.div`
    ${tablet} {
      flex: 1;
    }
    ${desktop} {
      padding-right: 6.375rem;
    }
  `,
  Title: styled(MDSText.TitleMd)``,
  SubTitle: styled(MDSText.BodyHeavyDefault)`
    margin-top: 1rem;
  `,
  Description: styled(MDSText.BodyRegDefault)`
    margin-top: 1rem;
  `,
  SmallDescription: styled(MDSText.BodyRegDefault)`
    margin-top: 0.25rem;
    margin-bottom: 1rem;
  `,
  ContentLink: styled(MDSAnchorButton)`
    text-decoration: none;
    margin-top: 1rem;
    border-color: ${(p) => p.theme.midwestColors.textLink};
    color: ${(p) => p.theme.midwestColors.textLink};

    ${tablet} {
      display: inline-flex;
    }
  `,
  DisclaimerContainer: styled(Container)`
    text-align: left;
    grid-column: span 3;
    padding: 1rem 0;
  `,
  DisclaimerText: styled(MDSText.BodyRegXs)`
    a {
      font-size: 1em;
      text-decoration: underline;
    }
  `,
};
