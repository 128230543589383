import { Chart, Document } from '../../svg';
import {
  Arrow,
  Container,
  LinkContainer,
  ProductLink,
  Subtitle,
} from './Content.style';
import { NeedsBasedRecOption, needsBasedRecAtom } from './selection.atom';
import { ButtonLinkVideo } from '@exploration/shared-components';
import { useAtom } from 'jotai';

export type RightContentType = {
  links: {
    linkText: string;
    href?: string;
    icon?: string;
    videoId?: string;
  }[];
};

export interface RightContentProps {
  rightContentData?: {
    [NeedsBasedRecOption.PERSONALIZED_FINANCIAL_PLANNING]: RightContentType;
    [NeedsBasedRecOption.BUDGETING_AND_SAVING]: RightContentType;
    [NeedsBasedRecOption.RETIREMENT_PLANNING]: RightContentType;
    [NeedsBasedRecOption.INVESTING]: RightContentType;
    [NeedsBasedRecOption.GENEROSITY_AND_GIVING]: RightContentType;
    [NeedsBasedRecOption.GETTING_INSURED]: RightContentType;
  };
}

// --------------------------------------------------------------------------

export const RightContent = ({ rightContentData }: RightContentProps) => {
  const [needsBasedData] = useAtom(needsBasedRecAtom);

  if (!needsBasedData.selectedOption || !rightContentData) {
    return null;
  }
  const selectedContent = rightContentData[needsBasedData.selectedOption];

  return (
    <Container>
      <Subtitle as="h1">Resources</Subtitle>
      {selectedContent.links.map((link, index) =>
        link.videoId ? (
          <ButtonLinkVideo
            key={link.videoId}
            titleText={link.linkText}
            videoId={link.videoId}
            variant
          />
        ) : (
          // eslint-disable-next-line react/no-array-index-key
          <LinkContainer key={index}>
            {link.icon === 'Document' ? <Document /> : <Chart />}
            <ProductLink href={link.href} target="_blank" type="navigational">
              {link.linkText}
            </ProductLink>
            <Arrow />
          </LinkContainer>
        ),
      )}
    </Container>
  );
};
