import { MDSButton } from '@midwest/web/button';
import { MDSTextInput } from '@midwest/web/forms';
import { InputContainer } from '@midwest/web/shared';
import { Search } from '@mui/icons-material';
import { desktop } from '@thrivent/midwest-shared';
import styled from 'styled-components';

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  margin-bottom: 2rem;

  ${desktop} {
    max-width: 30.25rem;
    flex-direction: row;
    .search-input {
      flex-grow: 1;
      padding-right: 0.5rem;
    }
  }
`;

export const Input = styled(MDSTextInput)`
  ${InputContainer} {
    height: 3.25rem;
  }
  ${desktop} {
    flex-grow: 1;
    padding-right: 0.5rem;
  }
`;

export const SearchIcon = styled(Search)`
  margin-left: 0.5rem;
`;

export const SearchButton = styled(MDSButton)`
  margin-top: 0.5rem;
  height: 3.25rem;
  background: ${(p) => p.theme.midwestColors.identityBrandPrimaryMedium};
  width: 100%;
  padding: 1rem;
  ${desktop} {
    align-self: flex-end;
    width: auto;
  }
`;
