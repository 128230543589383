import { desktop, tablet } from '@thrivent/midwest-shared';
import { MDSText } from '@thrivent/midwest-web';
import styled from 'styled-components';

export const HorizontalPageContainer = styled.div`
  width: 100%;
  overflow: hidden;
`;

export const HorizontalContentContainer = styled.div`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  margin-top: 3rem;
  margin-bottom: 2rem;
`;

export const ExtendedEllipsisContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  ::after {
    content: '';
    position: absolute;
    background-image: radial-gradient(#363636 0%, #363636 100%);
    background-size: cover;
    background-position: center;
    left: 50%;
    bottom: 0;
    width: 200%;
    transform: translateX(-50%);
    border-radius: 50%;
    height: 25rem;
    z-index: -1;
  }
`;

export const CenteredContentInEllipsis = styled.div`
  width: 100%;
  text-align: center;
  margin-top: auto;
  background-color: #363636;
  align-items: center;
`;

export const GreyBlockBottom = styled.div`
  width: 100%;
  background-color: #363636;
  margin-top: auto;
`;

export const TextContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const TitleHeader = styled(MDSText.HeroXl)`
  text-align: center;
  width: 22.4rem;
  font-size: 2.25rem;
  margin: 1rem auto;
  line-height: 2.5rem;

  ${tablet} {
    width: 32.75rem;
    font-size: 2.5rem;
    line-height: 3.5rem;
  }

  ${desktop} {
    font-size: 3rem;
    width: 42rem;
  }
`;

export const ConsultationLink = styled(MDSText.BodyHeavyDefault)`
  margin: 1rem auto;
  text-align: center;
  color: ${(p) => p.theme.midwestColors.identityBrandPrimaryHeavy};
`;

export const QuoteBlock = styled(MDSText.HeroMd)`
  color: ${(p) => p.theme.midwestColors.identityBrandPrimaryFaint};
  text-align: center;
  font-size: 1.25rem;
  width: 100%;
  max-width: 22.5rem;
  margin: 0 auto;
  margin-top: 1rem;

  ${tablet} {
    max-width: 33rem;
    font-size: 1.25rem;
  }

  ${desktop} {
    font-size: 2rem;
    max-width: 61.25rem;
    margin-top: 3rem;
  }
`;

export const ImageRow = styled.div`
  display: flex;
  flex-direction: table-row;
  justify-content: center;
  margin-bottom: auto;
  position: relative;
  min-width: 104rem;
  margin-left: 12.5rem;
  z-index: 0;

  ${tablet} {
    margin-left: 0;
  }
`;

export const ImageBlock = styled.div`
  height: 12.25rem;
  width: 10.75rem;
  z-index: 1;
  margin: 0.5rem;

  ${tablet} {
    width: 11.75rem;
    height: 13.25rem;
  }
`;

export const AdvisorImage = styled.img``;

export const QuoteAuthor = styled(MDSText.BodyRegSm)`
  color: ${(p) => p.theme.midwestColors.identityBrandPrimaryFaint};
  font-size: 1rem;
  font-weight: 400;
  line-height: 0.5rem;
  margin-top: 1.5rem;
  margin-bottom: 4rem;

  ${desktop} {
    margin-top: 2.5rem;
  }
`;
